import ClipboardButton from "@/components/buttons/ClipboardButton";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { Copy, TriangleAlert } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function LoyaltyCompleteMessage() {
  const { t, i18n } = useTranslation();
  const CODE = "booking@takyon.io";

  const { hideModal } = useModal();
  return (
    <div>
      <div className="mb-10">
        <p className="text-2xl font-medium">{t("loyalty.complete.title")}</p>
        <p className="mb-2 text-base text-gray-600 mt-1">
          {t("loyalty.complete.text1")}
        </p>
        <ClipboardButton code={CODE} />
        <p className="mb-2 text-base text-gray-600 mt-1">
          {t("loyalty.complete.text2")}
        </p>
        <div className="flex gap-4">
          <p
            className="mb-6 text-base underline cursor-pointer mt-1 font-medium"
            onClick={() =>
              window.open(`/pdf/guida_outlook_${i18n.language}.pdf`, "_blank")
            }
          >
            {t("loyalty.complete.text5")}
          </p>
          <p
            className="mb-6 text-base underline cursor-pointer mt-1 font-medium"
            onClick={() =>
              window.open(`/pdf/guida_gmail_${i18n.language}.pdf`, "_blank")
            }
          >
            {t("loyalty.complete.text6")}
          </p>
          <p
            className="mb-6 text-base underline cursor-pointer mt-1 font-medium"
            onClick={() =>
              window.open(`/pdf/guida_generica_${i18n.language}.pdf`, "_blank")
            }
          >
            {t("loyalty.complete.text3")}
          </p>
        </div>
        <div className="flex gap-2 border rounded-lg py-3 px-6 items-center">
          <div className="flex gap-2">
            <TriangleAlert size={36} className="font-medium h-[24px]" />
            <p className="text-base font-medium">
              {t("loyalty.complete.text4")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <Button
            variant={"takyon"}
            className=""
            type="button"
            onClick={() => {
              hideModal();
            }}
          >
            {t("boarding.step3.gotocollection")}
          </Button>
        </div>
      </div>
    </div>
  );
}
