import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CollectionPageChildProps } from "../CollectionPage";
import { getDefaultLink } from "@/lib/links";
import { useModal } from "@/context/ModalContext";
import completeImageITA from "@/assets/img/onboarding_complete_ita.png";
import completeImageEn from "@/assets/img/onboarding_complete_en.png";
import completeImageEsp from "@/assets/img/onboarding_complete_esp.png";

const CompletionMessage = () => {
  // const { setBackBtn, setProgress, setInfoTip } = useOutletContext<any>();
  // const navigate = useNavigate();
  // const { collectionId } = useParams<{ collectionId: string }>();
  const { t, i18n } = useTranslation();

  const { hideModal } = useModal();

  // useEffect(() => {
  //   setBackBtn(false);
  //   setProgress(100);
  //   setInfoTip("");
  // }, []);

  const getCompletionImage = () => {
    switch (i18n.language) {
      case "es":
        return completeImageEsp;
      case "en":
        return completeImageEn;
      default:
        return completeImageITA;
    }
  };

  return (
    <div>
      <div>
        <p className="text-2xl font-medium">{t("boarding.complete.title")}</p>
        <p className="mb-8 text-base text-gray-600 mt-1">
          {t("boarding.complete.subtitle")}
        </p>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center">
          <img
            src={getCompletionImage()}
            alt={t("boarding.complete.image_alt")}
            className="mb-8 max-w-[100%]"
          />
        </div>
        <div className="flex flex-row justify-center">
          <Button
            variant={"takyon"}
            className=""
            type="button"
            onClick={() => {
              // const link = getDefaultLink(collection);
              // navigate(link);
              hideModal();
            }}
          >
            {t("boarding.step3.gotocollection")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletionMessage;
