import { CheckCheck, Copy } from "lucide-react";
import { Button } from "../ui/button";
import { useState } from "react";

export default function ClipboardButton({ code }: { code: string }) {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Button
      variant="secondary"
      className={`mb-6 ${isCopied ? "bg-gray-300" : "bg-gray-200"}`}
      onClick={() => {
        setIsCopied(true);
        navigator.clipboard.writeText(code);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      }}
    >
      {code}
      {isCopied ? (
        <CheckCheck size={16} className="ml-2" />
      ) : (
        <Copy size={16} className="ml-2" />
      )}
    </Button>
  );
}
