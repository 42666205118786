import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDashboardData } from "@/hooks/dashboard";
import getLink, { getDefaultLink, LINK_TYPE } from "@/lib/links";
import {
  COLLECTION_PERMISSIONS,
  createCollection,
  iCollection,
  onBoardingCompleted,
  onBoardingLastStep,
} from "@/services/collection";
import { createGroup, getGroup, iGroup } from "@/services/groups";
import { ChevronDown, ChevronUp, CirclePlus, Group, Hotel } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Skeleton } from "../ui/skeleton";
import { useRbac } from "@/hooks/rbac";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { selectUser } from "@/store/userSlice";
import { useSelector } from "react-redux";
import { fireJuneEvent } from "../analytics/JuneAnalytics";

interface CollectionSelectorProps {
  collection: iCollection;
  className?: string;
}

export default function CollectionSelector({
  collection,
  className,
}: CollectionSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { can } = useRbac();
  const user = useSelector(selectUser);

  const { groups, collectionsGroupedByGroupId, isLoading } = useDashboardData();

  const handleCollectionClick = (collection: iCollection) => {
    const onboardingComplete = onBoardingCompleted(collection);
    //const onboardingComplete = true;

    if (!onboardingComplete && can(COLLECTION_PERMISSIONS.update, collection)) {
      // navigate(
      //   getLink(LINK_TYPE.COLLECTION_ONBOARDING, {
      //     __COLLECTIONID__: collection._id,
      //   })
      // );
      const lastStep = onBoardingLastStep(collection);
      navigate(`/collection/${collection._id}/infos/${lastStep}`);
    } else {
      const link = getDefaultLink(collection);
      navigate(link);
    }
  };

  const handleCreateGroup = async () => {
    try {
      const group = await getGroup(collection.groupId);

      console.log("group: ", group);

      const new_collection = await createCollection({
        groupId: group?._id,
        name: t("collection.noname_collection"),
      });

      fireJuneEvent("add_collection", {
        collectionName: new_collection.name,
        collectionType: new_collection.type,
        collectionId: new_collection._id,
      });

      navigate(`/collection/${new_collection._id}/infos/selecthotel`);
    } catch (error) {
      console.log(error);
    }
  };

  return isLoading ? (
    <Skeleton className="w-[200px] h-[28px]" />
  ) : (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild className="w-fit min-w-[200px]">
        <div
          className={`flex items-start items-center cursor-pointer text-xl font-medium ${
            className || ""
          }`}
        >
          <p>
            {collection
              ? collection.name
              : t("collection_selector_placeholder")}
          </p>
          {open ? (
            <ChevronUp className="ml-2 h-4 w-4" />
          ) : (
            <ChevronDown className="ml-2 h-4 w-4" />
          )}
          {!collection.isFree && (
            <div className="ml-2 px-2 py-[2px] rounded-full bg-gradient">
              <p className="text-sm font-bold">Pro</p>
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
        <DropdownMenuItem onClick={() => navigate(getLink(LINK_TYPE.HOME))}>
          <Group size={18} className="text-gray-400 mr-2" />
          <p className="text-sm text font-medium">{t("collection_view_all")}</p>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {groups.map((group: iGroup, index: number) => {
          const collections = collectionsGroupedByGroupId[group._id] || [];

          return (
            <div key={group._id}>
              {index > 0 && <DropdownMenuSeparator />}
              <DropdownMenuLabel>{group.name}</DropdownMenuLabel>
              {collections.map((collection: iCollection) =>
                !can(COLLECTION_PERMISSIONS.update, collection) &&
                !onBoardingCompleted(collection) ? (
                  //!onBoardingCompleted ? (
                  <TooltipProvider key={collection._id}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <DropdownMenuItem key={collection._id}>
                          <Hotel size={18} className="text-gray-400 mr-2" />
                          <p className="text-sm text text-gray-400">
                            {collection.name}
                          </p>
                        </DropdownMenuItem>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{t("tooltips.collection_missing_permission")}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <DropdownMenuItem
                    key={collection._id}
                    onClick={() => handleCollectionClick(collection)}
                  >
                    <Hotel size={18} className="text-gray-400 mr-2" />
                    <p className="text-sm text">{collection.name}</p>
                  </DropdownMenuItem>
                )
              )}
            </div>
          );
        })}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => handleCreateGroup()}>
          <CirclePlus className="mr-2" />
          <p className="text-sm text">{t("collection_add_collection")}</p>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
