import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { CollectionPageChildProps } from "../CollectionPage";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2, X } from "lucide-react";
import {
  getCollections,
  patchCollection,
  sendOnboardingEmail,
} from "@/services/collection";
import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";
import { fireTagManagerEvent } from "@/services/tagmanager";
import { getGroup } from "@/services/groups";

interface IService {
  label: string;
  value: string;
  index: number;
}

export default function HotelBenefits({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initialServices = [
    {
      label: t("onboarding.lowest_price"),
      value: "lowest_price",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "lowest_price"
        ) || false,
    },
    {
      label: t("onboarding.early_checkin"),
      value: "early_checkin",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "early_checkin"
        ) || false,
    },
    {
      label: t("onboarding.post_checkout"),
      value: "late_checkout",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "late_checkout"
        ) || false,
    },
    {
      label: t("onboarding.welcome_drink"),
      value: "welcome_drink",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "welcome_drink"
        ) || false,
    },
    {
      label: t("onboarding.brekfast_included"),
      value: "free_breakfast",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_breakfast"
        ) || false,
    },
    {
      label: t("onboarding.spa_access"),
      value: "spa_access",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "spa_access"
        ) || false,
    },
    {
      label: t("onboarding.free_treatments"),
      value: "spa_treatments",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "spa_treatments"
        ) || false,
    },
    {
      label: t("onboarding.fidelity_program"),
      value: "loyalty_program",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "loyalty_program"
        ) || false,
    },
    {
      label: t("onboarding.restourant_discount"),
      value: "restaurant_discount",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "restaurant_discount"
        ) || false,
    },
    {
      label: t("onboarding.free_parking"),
      value: "free_parking",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_parking"
        ) || false,
    },
    {
      label: t("onboarding.pool_included"),
      value: "pool_included",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "pool_included"
        ) || false,
    },
    {
      label: t("onboarding.beach_included"),
      value: "beach_included",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "beach_included"
        ) || false,
    },
    {
      label: t("onboarding.customized_packages"),
      value: "flexibility",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "flexibility"
        ) || false,
    },
    {
      label: t("onboarding.free_minibar"),
      value: "free_minibar",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_minibar"
        ) || false,
    },
    {
      label: t("onboarding.boat_tour"),
      value: "boat_tour",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes("boat_tour") ||
        false,
    },
    {
      label: t("onboarding.flexibility"),
      value: "flexibility",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "flexibility"
        ) || false,
    },
    {
      label: t("onboarding.free_experiencies"),
      value: "free_experiencies",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_experiencies"
        ) || false,
    },
    {
      label: t("onboarding.free_excursions"),
      value: "free_excursions",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_excursions"
        ) || false,
    },
    {
      label: t("onboarding.free_attractions"),
      value: "free_attractions",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "free_attractions"
        ) || false,
    },
    {
      label: t("onboarding.trentino_card"),
      value: "trentino_card",
      status:
        collection?.preData?.advantagesOnDirectWebsite?.includes(
          "trentino_card"
        ) || false,
    },
  ];

  const [services, setServices] = useState(initialServices);
  const [selectedServices, setSelectedServices] = useState<IService[]>(() => {
    return initialServices
      .map((service, index) => ({
        label: service.label,
        value: service.value,
        index,
      }))
      .filter((service) =>
        collection?.preData?.advantagesOnDirectWebsite?.includes(service.value)
      );
  });

  const toggleService = (index: number, newValue?: boolean) => {
    setServices(
      services.map((service, i) =>
        i === index
          ? {
              ...service,
              status: newValue !== undefined ? newValue : !service.status,
            }
          : service
      )
    );
  };

  useEffect(() => {
    setBackBtn(true);
    setProgress(80);
  }, []);

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (selectedServices.length > 0) {
      try {
        await patchCollection({
          collectionId: collection?._id,
          preData: {
            ...collection?.preData,
            advantagesOnDirectWebsite: selectedServices.map((s) => s.value),
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        await onRefresh();
      }
    }

    const group = await getGroup(collection?.groupId || "");

    const groupArray = [collection?.groupId];

    const collections = await getCollections({
      page: 1,
      size: 1000,
      query: JSON.stringify({
        groupId: { $in: groupArray },
      }),
    });

    if (collections.data.length === 1) {
      navigate(`/collection/${collections.data[0]?._id}/infos/hotelchain`);
    } else {
      try {
        await patchCollection({
          collectionId: collection?._id,
          census: {
            ...collection?.census,
            groupName: group?.name || "",
          },
        });

        await sendOnboardingEmail(collection?._id || "");
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        await onRefresh();
        fireTagManagerEvent("create_collection", {
          name_collection: collection?.name,
        });
        fireJuneEvent("create_collection", {
          name_collection: collection?.name,
        });

        navigate(`/collection/${collection?._id}/analytics`, {
          state: { openModal: true },
        });
      }

      navigate(`/collection/${collection?._id}/analytics`, {
        state: { openModal: true },
      });
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-5 mb-10">
        <p className="text-base font-medium">{t("onboarding.step")} 4</p>
        <p className="text-2xl font-medium">
          {t("onboarding.hotel_benefits_title")}
        </p>
      </div>
      <div className="flex-1 overflow-y-auto ">
        <div className="flex flex-wrap gap-4 mb-10">
          {selectedServices.map((service) => (
            <div
              key={service.value + "Selected"}
              className={`flex align-center justify-between py-3 px-6 rounded-xl border border-black border-2`}
            >
              <div className="select-none">
                <p className="text-base font-medium flex items-center gap-2">
                  {service.label}
                  <X
                    className="cursor-pointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleService(service.index, false);
                      setSelectedServices((prevServices) =>
                        prevServices.filter((s) => s.index !== service.index)
                      );
                    }}
                  />
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap gap-4">
          {services.map((service, index) => (
            <>
              {service.status === false && (
                <div
                  key={service.value}
                  className={`flex align-center justify-between py-3 px-6 rounded-xl border border-gray-200 ${
                    selectedServices.length < 3 ? "cursor-pointer" : ""
                  }`}
                  onClick={() => {
                    if (selectedServices.length < 3) {
                      toggleService(index, true);
                      setSelectedServices([
                        ...selectedServices,
                        {
                          label: service.label,
                          value: service.value,
                          index: index,
                        },
                      ]);
                    }
                  }}
                >
                  <div className="select-none">
                    <p className="text-base font-medium flex items-center gap-2">
                      {service.label}
                    </p>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="mt-10">
        <div className="flex flex-row justify-between">
          <Button
            className=""
            type="button"
            onClick={() =>
              navigate(`/collection/${collection?._id}/infos/hotelprice`)
            }
          >
            {t("auth.back")}
          </Button>
          {!isLoading ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={(e: any) => onSubmit(e)}
              disabled={false}
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button disabled className="min-w-40">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("buttons.loading")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
