import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { createPaymentMethod, getSubscriptionLink } from "@/services/payment";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { useState, useEffect, ReactNode, useRef } from "react";
import { iCollection } from "@/services/collection";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { useLocation } from "react-router-dom";
import { getStripeRedirectUrl } from "@/lib/links";

export interface FormData {
  name: string;
  billingDetails: z.infer<typeof formSchema>;
}

// Define form schema using zod
const formSchema = z.object({
  vat: z.string().min(1, "VAT is required"),
  fiscalCode: z.string().optional(),
  name: z.string().min(1, "Business name is required"),
  zip: z.string().min(1, "ZIP code is required"),
  city: z.string().optional(),
  address: z.string().min(1, "Address is required"),
  province: z.string().min(1, "Province is required"),
  contactName: z.string().optional(),
  contactPhone: z.string().optional(),
  recipientCode: z.string().min(1, "Recipient code is required"),
  pec: z.string().email("Invalid PEC email"),
});

export function PaymentMethodsModal({
  collection,
  onSuccess,
  externalButton,
}: {
  collection: iCollection;
  onSuccess: (formData?: FormData) => void;
  externalButton?: (handleSubmit: () => void) => ReactNode;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const paymentDefaultValue = collection?.name
    ? `${t("paymentmethods.prefix")} ${collection.name}`
    : "";

  const [name, setName] = useState(paymentDefaultValue);

  // current route
  const { pathname } = useLocation();

  const { hideModal } = useModal();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vat: "",
      fiscalCode: "",
      name: "",
      zip: "",
      city: "",
      address: "",
      province: "",
      contactName: "",
      contactPhone: "",
      recipientCode: "",
      pec: "",
    },
  });

  // Function to trigger form submission programmatically
  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const payload: FormData = {
        name,
        billingDetails: values,
      };

      // If we're using an external button, we don't need to do anything else
      // Just call onSuccess with the form data
      if (externalButton) {
        onSuccess(payload);
        return;
      }

      const paymentMethod = await createPaymentMethod(payload);

      // get current route to build the redirect
      const redirectForStripe = getStripeRedirectUrl(
        pathname,
        paymentMethod._id
      );

      const link = await getSubscriptionLink(
        paymentMethod._id,
        redirectForStripe
      );

      console.log("paymentMethod", paymentMethod);
      console.log("getSubscriptionLink", link);

      onSuccess(); // No need to pass data here

      window.location.href = link;
    } catch (error) {
      console.log(error);
      // Handle errors if needed
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      ref={formRef}
      id="payment-form"
      onSubmit={form.handleSubmit(onSubmit)}
      className="space-y-4"
    >
      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.pm_name")} {t("form.required")}
        </label>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t("payment.placeholder_pm_name")}
          required
          type="text"
          className="w-full"
        />
      </div>

      <Separator />

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.vat")} {t("form.required")}
        </label>
        <Input
          {...form.register("vat")}
          placeholder={t("payment.placeholder_vat")}
          required
          type="text"
          className="w-full"
        />
        {form.formState.errors.vat && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.vat.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.fiscal_code")}
        </label>
        <Input
          {...form.register("fiscalCode")}
          placeholder={t("payment.placeholder_fiscal_code")}
          type="text"
          className="w-full"
        />
        {form.formState.errors.fiscalCode && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.fiscalCode.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.business_name")} {t("form.required")}
        </label>
        <Input
          {...form.register("name")}
          placeholder={t("payment.placeholder_business_name")}
          required
          type="text"
          className="w-full"
        />
        {form.formState.errors.name && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.name.message}
          </p>
        )}
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.zip")} {t("form.required")}
          </label>
          <Input
            {...form.register("zip")}
            placeholder={t("payment.placeholder_zip")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.zip && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.zip.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.city")}
          </label>
          <Input
            {...form.register("city")}
            placeholder={t("payment.placeholder_city")}
            type="text"
            className="w-full"
          />
          {form.formState.errors.city && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.city.message}
            </p>
          )}
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.address")} {t("form.required")}
          </label>
          <Input
            {...form.register("address")}
            placeholder={t("payment.placeholder_address")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.address && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.address.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.province")} (es. MI) {t("form.required")}
          </label>
          <Input
            {...form.register("province")}
            placeholder={t("payment.placeholder_province")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.province && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.province.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex gap-2 ">
        <div className="flex-1">
          <label className="block text-sm font-medium mb-2">
            {t("payment.contact_name")}
          </label>
          <Input
            {...form.register("contactName")}
            placeholder={t("payment.placeholder_contact_name")}
            type="text"
            className="w-full"
          />
          {form.formState.errors.contactName && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.contactName.message}
            </p>
          )}
        </div>

        <div className="flex-1">
          <label className="block text-sm font-medium mb-2">
            {t("payment.contact_phone")}
          </label>
          <Input
            {...form.register("contactPhone")}
            placeholder={t("payment.placeholder_contact_phone")}
            type="text"
            className="w-full"
          />
          {form.formState.errors.contactPhone && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.contactPhone.message}
            </p>
          )}
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.recipient_code")} {t("form.required")}
          </label>
          <Input
            {...form.register("recipientCode")}
            placeholder={t("payment.placeholder_recipient_code")}
            required
            type="text"
            className="w-full mt-4"
          />
          {form.formState.errors.recipientCode && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.recipientCode.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.pec")} {t("form.required")}
          </label>
          <Input
            {...form.register("pec")}
            placeholder={t("payment.placeholder_pec")}
            required
            type="email"
            className="w-full"
          />
          {form.formState.errors.pec && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.pec.message}
            </p>
          )}
        </div>
      </div>

      {!externalButton && (
        <div className="flex justify-between pt-4">
          <Button
            variant="destructive"
            onClick={() => {
              hideModal();
            }}
          >
            {t("buttons.cancel")}
          </Button>
          <LoadingSubmitButton form="payment-form" isLoading={isLoading}>
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      )}

      {externalButton && externalButton(triggerSubmit)}
    </form>
  );
}
