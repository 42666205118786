import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useEffect, useState, ReactElement, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useRbac } from "@/hooks/rbac";
import { useTranslation } from "react-i18next";
import { getPaymentMethod, iPaymentMethod } from "@/services/payment";
import { CollectionPageChildProps } from "@/pages/collection/CollectionPage";
import PaymentMethodTab from "@/pages/collection/paymentMethod/PaymentMethodTab";
import {
  getNftsOfCollectionPaginated,
  iNft,
  NFT_SELL_STATUS,
} from "@/services/nft";
import NftsToCheckDatatable from "@/pages/collection/NftsToCheckDatatable";
import { H3 } from "../typography/Typography";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  EllipsisVertical,
  Settings,
  SquarePen,
  TriangleAlert,
  Users,
  Image,
  CreditCard,
  CirclePlus,
} from "lucide-react";
import ButtonCreateNft from "./ButtonCreateNft";
import {
  COLLECTION_PERMISSIONS,
  createCollection,
  iCollection,
} from "@/services/collection";
import { Skeleton } from "../ui/skeleton";
import TaksTab from "@/pages/collection/taks/TaksTab";
import CollectionSelector from "./CollectionSelector";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useModal } from "@/context/ModalContext";
import DetailsForm from "@/pages/collection/onboarding/DetailsForm";
import ImagesForm from "@/pages/collection/onboarding/ImagesForm";
import CollectionTeam from "./CollectionTeam";
import CollectionSettingsModal from "./CollectionSettingsModal";
import { getGroup, GROUP_PERMISSIONS, iGroup } from "@/services/groups";
import getLink, { LINK_TYPE } from "@/lib/links";
import ErrorPage from "../errors/ErrorPage";
import DirectSearch from "@/pages/collection/ds/DirectSearch";
import { Button } from "../ui/button";
import GoToProModal from "@/pages/collection/analytics/GoToProModal";
import { fireJuneEvent } from "../analytics/JuneAnalytics";
import LoyaltyContainer from "@/pages/collection/loyalty/loyalty_container";

interface iMenuItemContent {
  component: ReactElement;
  title: string;
  description: string;
}

interface iMenuItem {
  icon: ReactElement;
  text: string;
  content: iMenuItemContent;
}

export default function CollectionTabsWrapper({
  group,
  collection,
  loading,
  onRefresh,
}: CollectionPageChildProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isSuperAdmin } = useRbac();

  const [pm, setPm] = useState<iPaymentMethod>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nftsToCheck, setNftsToCheck] = useState<iNft[]>([]);
  const { "*": currentTab } = useParams<{
    "*": string;
  }>();

  const [tab, setTab] = useState(currentTab);
  const { can } = useRbac();
  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  const loadCollectionData = async () => {
    if (!collection) return;

    setIsLoading(true);

    try {
      if (collection.paymentMethodId) {
        const pm = await getPaymentMethod(collection.paymentMethodId);
        setPm(pm);
      }
    } catch (err) {
      console.log("Error loading collection data", err);
    }

    setIsLoading(false);
  };

  const loadNftsPendingSale = async () => {
    if (!collection) return;

    try {
      const nfts = await getNftsOfCollectionPaginated({
        collectionId: collection._id,
        page: 1,
        size: 500,
        query: { sellStatus: NFT_SELL_STATUS.PENDING_SALE },
      });
      setNftsToCheck(nfts.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadCollectionData();
    loadNftsPendingSale();
  }, [collection]);

  const handleTabChange = (value: string) => {
    switch (value) {
      case "analytics":
        setTab("analytics");
        navigate(`analytics`);
        break;
      case "taks":
        setTab("taks");
        navigate(`taks`);
        fireJuneEvent("view_tak", {
          collectionName: collection?.name,
          collectionId: collection?._id,
        });
        break;
      case "loyalty":
        setTab("loyalty");
        navigate(`loyalty`);
        break;
      case "buybacks":
        setTab("buybacks");
        navigate(`buybacks`);
        break;
      // case "supply":
      //   setTab("supply");
      //   navigate(`supply`);
      //   break;
      case "payments":
        setTab("payments");
        navigate(`payments`);
        break;
      case "saleEvents":
        setTab("saleEvents");
        navigate(`saleEvents`);
        break;
      default:
        navigate(`taks`);
    }
  };
  const { showModal, hideModal } = useModal();

  const menuItems: (iMenuItem | false)[] = useMemo(() => {
    if (collection)
      return [
        can(COLLECTION_PERMISSIONS.update, collection) && {
          icon: <SquarePen size={18} className="text-gray-400 mr-2" />,
          text: t("collection.hotel_basics"),
          content: {
            component: (
              <DetailsForm
                collection={collection as iCollection}
                onRefresh={onRefresh}
              />
            ),
            title: t("collection.hotel_basics"),
            description: "",
          },
        },
        can(COLLECTION_PERMISSIONS.update, collection) &&
          !collection.isFree && {
            icon: <Image size={18} className="text-gray-400 mr-2" />,
            text: t("collection.hotel_images"),
            content: {
              component: (
                <ImagesForm
                  collection={collection as iCollection}
                  onRefresh={onRefresh}
                />
              ),
              title: t("collection.hotel_images"),
              description: "",
            },
          },
        can(COLLECTION_PERMISSIONS.memebers, collection) && {
          icon: <Users size={18} className="text-gray-400 mr-2" />,
          text: t("collection.members"),
          content: {
            component: (
              <CollectionTeam
                onRefresh={onRefresh}
                collection={collection as iCollection}
                group={group as iGroup}
              />
            ),
            title: t("collection.members"),
            description: "",
          },
        },
        isSuperAdmin && {
          icon: <Settings size={18} className="text-gray-400 mr-2" />,
          text: t("collection.settings"),
          content: {
            component: (
              <CollectionSettingsModal
                onRefresh={() => {
                  onRefresh();
                  hideModal();
                }}
                collection={collection as iCollection}
                group={group as iGroup}
              />
            ),
            title: t("collection.settings"),
            description: "",
          },
        },
      ].filter((item): item is iMenuItem => Boolean(item));
    return [];
  }, [collection]);

  if (loading)
    return (
      <div>
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] w-[730px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[122px] rounded-lg" />
        </div>
      </div>
    );
  if (!collection || !group) {
    if (!isSuperAdmin && !loading) {
      navigate(getLink(LINK_TYPE.HOME));
      // We'll clear the cookie once navigation is stable in a future update
      document.cookie =
        "lastVisitedCollection=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
      return null;
    } else {
      return <ErrorPage message={t("collection.not_found")} />;
    }
  }

  // check for permissions
  const paymentEnabled =
    !collection.isFree &&
    can(COLLECTION_PERMISSIONS.view_payment_data, collection);

  // Array of user's actual permissions for menu items
  const userPermissions = [
    can(COLLECTION_PERMISSIONS.admin, collection) && "admin", // For "Crea nuovo Tak"
    can(COLLECTION_PERMISSIONS.update, collection) && "update", // For "Dettagli Hotel"
    can(COLLECTION_PERMISSIONS.update, collection) && "images", // For "Immagini hotel"
    can(COLLECTION_PERMISSIONS.memebers, collection) && "members", // For "Team"
    can(COLLECTION_PERMISSIONS.update, collection) && "settings", // For "Impostazioni"
    can(COLLECTION_PERMISSIONS.view_payment_data, collection) && "payments", // For "Pagamenti"
  ].filter(Boolean);

  const handleCreateGroup = async () => {
    try {
      const group = await getGroup(collection.groupId);

      console.log("group: ", group);

      const new_collection = await createCollection({
        groupId: group?._id,
        name: t("collection.noname_collection"),
      });

      fireJuneEvent("add_collection", {
        collectionName: new_collection.name,
        collectionType: new_collection.type,
        collectionId: new_collection._id,
      });

      navigate(`/collection/${new_collection._id}/infos/selecthotel`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="py-4 px-5 gap-2 md:gap-8 md:px-14 md:py-8">
        {nftsToCheck.length ? (
          <div className="p-4 my-4 border rounded-lg">
            <H3>{t("collection.nft_check_put_for_sale")}</H3>
            <div>
              <Alert className="mt-5">
                <TriangleAlert className="h-4 w-4" color="orange" />
                <AlertDescription>
                  {t("collection.nft_check_put_for_sale_text_2")}
                </AlertDescription>
              </Alert>
            </div>
            <NftsToCheckDatatable collection={collection} />
          </div>
        ) : null}
        {/* <BackToGroup group={group} /> */}
        <Tabs
          defaultValue="taks"
          className=""
          onValueChange={handleTabChange}
          value={tab}
        >
          <div className="flex gap-4 md:flex-row justify-between md:items-center">
            <div className="flex gap-1 items-center">
              {collection.name && (
                <CollectionSelector
                  collection={collection}
                ></CollectionSelector>
              )}
            </div>
            <div className="flex items-center justify-end gap-1">
              <Button variant={"black"} onClick={() => handleCreateGroup()}>
                <CirclePlus className="mr-2" />
                <p className="text-xs">{t("collection_add_collection")}</p>
              </Button>
              {collection.isFree && (
                <Button
                  className="hidden md:block"
                  variant={"black"}
                  onClick={() =>
                    showModal(<GoToProModal />, {
                      title: t("pro_modal.title"),
                      description: "",
                      className: "max-w-max",
                    })
                  }
                >
                  {t("collection.upgrade_to_pro")}
                </Button>
              )}
              {!collection.isFree && (
                <TabsList className="w-fit max-w-1/2">
                  {collection.dataAnalysisMaskId ? (
                    <TabsTrigger value="analytics">
                      {t("collection.tab_analytics")}
                    </TabsTrigger>
                  ) : null}
                  <TabsTrigger value="taks">
                    {t("collection.tab_bookings")}
                  </TabsTrigger>
                  {/* <TabsTrigger value="supply">
                {t("collection.tab_supply")}
              </TabsTrigger>
              <TabsTrigger value="buybacks">
                {t("collection.tab_buybacks")}
              </TabsTrigger>
              <TabsTrigger value="saleEvents">
                {t("collection.events")}
              </TabsTrigger>
              {paymentEnabled && (
                <TabsTrigger value="payments">
                  {t("collection.tab_payments")}
                </TabsTrigger>
              )}*/}
                </TabsList>
              )}
              {userPermissions.length > 0 && (
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenuTrigger asChild>
                    <div className="w-9 h-9 rounded-full flex items-center justify-center hover:bg-gray-100 cursor-pointer">
                      <EllipsisVertical size={15}></EllipsisVertical>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="mr-8">
                    {!collection.isFree && (
                      <ButtonCreateNft
                        collection={collection!}
                        onSuccess={onRefresh}
                      />
                    )}
                    {/* <DropdownMenuItem
                  className="cursor-pointer"
                  key={"saleEvents"}
                  onClick={() => handleTabChange("saleEvents")}
                >
                  <Repeat2 size={18} className="text-gray-400 mr-2" />{" "}
                  <p className="text-sm font-medium">
                    {t("collection.events")}
                  </p>
                </DropdownMenuItem> */}
                    {menuItems
                      .filter((item): item is iMenuItem => !!item)
                      .map((item: iMenuItem) => {
                        const menuKey = `menu-item-${item.text}`;
                        return (
                          <DropdownMenuItem
                            className="cursor-pointer"
                            key={menuKey}
                            onClick={() =>
                              showModal(item.content.component, {
                                title: item.content.title,
                                description: item.content.description,
                              })
                            }
                          >
                            {item.icon}
                            <p className="text-sm font-medium">{item.text}</p>
                          </DropdownMenuItem>
                        );
                      })}
                    {paymentEnabled && (
                      <DropdownMenuItem
                        className="cursor-pointer"
                        key="payments"
                        onClick={() =>
                          showModal(
                            <PaymentMethodTab
                              onRefresh={onRefresh}
                              collection={collection}
                              pm={pm}
                            ></PaymentMethodTab>,
                            {
                              title: t("collection.tab_payments"),
                              description: t(
                                "collection.tab_payments_description"
                              ),
                              className: "max-w-5xl",
                            }
                          )
                        }
                      >
                        <CreditCard size={18} className="text-gray-400 mr-2" />
                        <p className="text-sm font-medium">
                          {t("collection.tab_payments")}
                        </p>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </div>

          <Separator className="mt-6" />

          <TabsContent value="analytics">
            {/* <CollectionAnalyticsMaskTab collection={collection} /> */}
            <DirectSearch
              collection={collection}
              isFreeUser={collection.isFree}
            />
          </TabsContent>
          <TabsContent value="taks">
            <TaksTab collection={collection} />
          </TabsContent>
          <TabsContent value="loyalty">
            <LoyaltyContainer onRefresh={onRefresh} />
          </TabsContent>
          {/* {paymentEnabled && (
          <TabsContent value="payments">
            <PaymentMethodTab
              onRefresh={onRefresh}
              collection={collection}
              pm={pm}
            />
          </TabsContent>
        )} */}
          {/* <TabsContent value="buybacks">
          <BuybacksTab collection={collection} />
        </TabsContent> */}
          {/* <TabsContent value="supply">
          <SupplyTab collection={collection} />
        </TabsContent> */}
          {/* <TabsContent value="saleEvents">
          <SaleEventsTab collection={collection} />
        </TabsContent> */}
          {/* <TabsContent value="info">
          <InfoTab
            onRefresh={onRefresh}
            collection={collection}
            group={group}
          />
        </TabsContent> */}
        </Tabs>
      </div>
      <div className="md:hidden w-full flex fixed bottom-0 py-3 justify-center items-center bg-black gopro">
        {collection.isFree && (
          <Button
            variant={"gradient"}
            onClick={() =>
              showModal(<GoToProModal />, {
                title: "",
                description: "",
                className: "min-w-full px-1",
              })
            }
          >
            {t("collection.upgrade_to_pro")}
          </Button>
        )}
      </div>
    </>
  );
}
