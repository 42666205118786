import { iCollection } from "@/services/collection";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import { useSectionTitle } from "@/context/SectionTitleContext";
import TaksDatatable from "./TaksDatatable";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import BuybacksTab from "../buybacks/BuybacksTab";
import SaleEventsTab from "../sale-events/SaleEventsTab";
import {
  getAnalysisMaskData,
  MaskDataResponse,
} from "@/services/dataanalysismask";
import { Skeleton } from "@/components/ui/skeleton";
import Overview from "../analytics/Overview";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "@/context/ModalContext";
import CompletionMessage from "../onboarding/CompletionMessage";

export default function TaksTab({ collection }: { collection: iCollection }) {
  const { t } = useTranslation();
  const { setTitle } = useSectionTitle();
  const [tab, setTab] = useState("alltaks");
  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState<MaskDataResponse>();
  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const modalShownRef = useRef(false);

  const loadMaskData = async (period: number) => {
    try {
      setIsLoading(true);
      const datas = await getAnalysisMaskData({
        collectionId: collection._id,
        period: period,
      });
      // base data always available from the api
      setGraphData(datas);

      setIsLoading(false);
    } catch (error) {
      console.log("Error getting mask data", error);
    }
  };

  useEffect(() => {
    loadMaskData(30);
  }, []);

  useEffect(() => {
    setTitle(`${t("collection.tab_bookings")} - ${collection.name}`);
  }, [setTitle]);

  useEffect(() => {
    if (location.state?.openModal) {
      showModal(<CompletionMessage />, {
        title: "",
        description: "",
      });
      // Replace current location with same URL but without state
      navigate(location.pathname, { replace: true });
    }
  }, [location, showModal, navigate]);

  return isLoading ? (
    <div className="flex gap-4">
      <div className="flex flex-wrap gap-2 w-full">
        <Skeleton className="w-full h-[286px]" />
        <Skeleton className="w-full h-[521px]" />
      </div>
    </div>
  ) : (
    <div>
      {graphData && (
        <div className="flex flex-col mb-11 mt-8">
          <p className="text-xl font-semibold mb-5">
            {t("collection.analytics.generatedtak_title")}
          </p>

          <Overview data={graphData.base as any} collection={collection} />
        </div>
      )}
      <Tabs defaultValue="alltaks" value={tab} onValueChange={setTab}>
        <div className="w-full flex pb-5 justify-between">
          <div className="flex items-center justify-start gap-1">
            {tab === "alltaks" && (
              <>
                <p className="text-xl font-semibold">
                  {t("collection.tab_tak_title")}
                </p>
                <Popover>
                  <PopoverTrigger asChild>
                    <Info className="cursor-pointer" size={16} />
                  </PopoverTrigger>
                  <PopoverContent className="w-80 ml-5">
                    <p>{t("collection.tab_taks_description")}</p>
                  </PopoverContent>
                </Popover>
              </>
            )}
            {tab === "buybacks" && (
              <>
                <p className="text-xl font-semibold">
                  {t("collection.tab_buybacks")}
                </p>
                <Popover>
                  <PopoverTrigger asChild>
                    <Info className="cursor-pointer" size={16} />
                  </PopoverTrigger>
                  <PopoverContent className="w-80 ml-5">
                    <p>{t("collection.tab_buybacks_description")}</p>
                  </PopoverContent>
                </Popover>
              </>
            )}
            {tab === "saleEvents" && (
              <>
                <p className="text-xl font-semibold">
                  {t("collection.events")}
                </p>
              </>
            )}
          </div>
          <div className="flex items-center gap-1 justify-end">
            <TabsList className="w-fit max-w-1/2">
              <TabsTrigger value="alltaks">
                {t("collection.tab_bookings")}
              </TabsTrigger>
              <TabsTrigger value="buybacks">
                {t("collection.tab_buybacks")}
              </TabsTrigger>
              <TabsTrigger value="saleEvents">
                {t("collection.events")}
              </TabsTrigger>
            </TabsList>
          </div>
        </div>
        <TabsContent value="alltaks">
          <TaksDatatable collection={collection} />
        </TabsContent>
        <TabsContent value="buybacks">
          <BuybacksTab collection={collection} />
        </TabsContent>
        <TabsContent value="saleEvents">
          <SaleEventsTab collection={collection} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
