import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
  ChartConfig,
} from "@/components/ui/chart";
import {
  MaskDataResponse,
  searchResponse,
  trafficResponse,
} from "@/services/dataanalysismask";
import { formatNumberEuropean } from "@/utils/generic";
import { t } from "i18next";
import { BadgeEuro, MousePointerClick, TextSearch } from "lucide-react";
import { CartesianGrid, XAxis, Bar, BarChart, Tooltip } from "recharts";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SearchChart({ data }: { data: MaskDataResponse }) {
  const { i18n } = useTranslation();
  const trafficConfig = {
    amount: {
      label: t("ds.visits"),
      color: "#8CE786",
    },
  } satisfies ChartConfig;

  const searchData = data.search as searchResponse;
  const trafficData = data.traffic as trafficResponse;
  const trafficValueData = data.traffic_value as trafficResponse;

  if (!searchData.data) return null;

  const searchDataWithDate = searchData.data.map((item) => ({
    ...item,
    date: `${item.year}-${String(item.month + 1).padStart(2, "0")}-${String(
      item.day
    ).padStart(2, "0")}`,
  }));

  return (
    <div className="flex flex-col md:grid md:grid-cols-[4fr_1fr] gap-4">
      <div className="border rounded-xl flex flex-col shadow-md">
        <div className="w-full flex justify-between border-b">
          <div className="flex flex-col py-3 px-5 gap-1">
            <div className="flex align-items-center gap-2">
              <TextSearch></TextSearch>
              <p className="text-xl font-medium">{t("ds.visits")}</p>
            </div>
            <p className="text-sm font-medium text-gray-400">
              {t("ds.visits_description")}
            </p>
          </div>
          <div className="flex flex-col py-3 px-5 border-l bg-gray-100">
            <p className="text-sm font-medium">{t("ds.dashboard_search")}</p>
            <p className="text-3xl font-semibold">
              {formatNumberEuropean(Number(searchData.total?.toFixed(0)), 0)}
            </p>
          </div>
        </div>
        <ChartContainer
          className="p-4 h-[440px] w-full aspect-auto"
          config={trafficConfig}
        >
          <BarChart
            data={searchDataWithDate}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              tickFormatter={(value) => {
                const date = new Date(value);
                const language = i18n.language || "en";
                const formatter = new Intl.DateTimeFormat(language, {
                  month: "short",
                  day: "numeric",
                }).formatToParts(date);

                const month =
                  formatter.find((part) => part.type === "month")?.value || "";
                const day =
                  formatter.find((part) => part.type === "day")?.value || "";
                return `${day} ${month.charAt(0).toUpperCase()}${month.slice(
                  1
                )}`;
              }}
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    const date = new Date(value);
                    const language = i18n.language || "en";
                    const formatter = new Intl.DateTimeFormat(language, {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }).formatToParts(date);

                    const month =
                      formatter.find((part) => part.type === "month")?.value ||
                      "";
                    const day =
                      formatter.find((part) => part.type === "day")?.value ||
                      "";
                    const year =
                      formatter.find((part) => part.type === "year")?.value ||
                      "";
                    return `${day} ${month
                      .charAt(0)
                      .toUpperCase()}${month.slice(1)} ${year}`;
                  }}
                  formatter={(value) => (
                    <div className="flex flex-1 justify-between leading-none items-center">
                      <span className="text-muted-foreground">
                        {t("ds.visits")}
                      </span>
                      <span className=" font-medium tabular-nums text-foreground ml-4">
                        {value.toLocaleString()}
                      </span>
                    </div>
                  )}
                  indicator="dot"
                />
              }
            />
            <ChartLegend
              content={<ChartLegendContent className="flex-row-reverse" />}
            />
            <Bar
              dataKey="amount"
              fill={trafficConfig.amount.color}
              radius={8}
            />
          </BarChart>
        </ChartContainer>
      </div>
      <div className="grid grid-rows-2 gap-4">
        <div className="flex flex-col justify-between border rounded-xl py-3 px-5 shadow-md">
          <div>
            <div className="w-full flex justify-between">
              <p className="text-xl font-medium">
                {t("ds.accomodation_traffic_title")}
              </p>
              <MousePointerClick />
            </div>
            <p className="text-sm font-medium text-gray-400 mt-3">
              {t("ds.accomodation_traffic_description")}
            </p>
          </div>
          <div className="flex justify-end mt-auto">
            <p className="text-4xl font-semibold">
              {trafficData.amount?.toFixed(0)} click
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between border rounded-xl py-3 px-5 shadow-md">
          <div>
            <div className="w-full flex justify-between">
              <p className="text-xl font-medium">{t("ds.revenue_title")}</p>
              <BadgeEuro />
            </div>
            <p className="text-sm font-medium text-gray-400 mt-3">
              {t("ds.revenue_description")}
            </p>
          </div>
          <div className="flex justify-end">
            <p className="text-4xl font-semibold">
              € {formatNumberEuropean(Number(trafficValueData.amount), 0)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
