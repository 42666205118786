import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Link, useNavigate } from "react-router-dom";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/context/AuthContext";
import { H2 } from "../typography/Typography";
import getLink, { LINK_TYPE } from "@/lib/links";
import { getUrlParameter } from "@/utils/generic";

export function LoginForm() {
  const { t } = useTranslation();
  const formSchema = z.object({
    email: z.string().email({
      message: t("auth.invalid_email"),
    }),
    password: z.string().min(6, {
      message: t("auth.password_too_short"),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: getUrlParameter("email") ?? "",
      password: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const { login } = useAuthContext();

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      await login(values);
    } catch (error: any) {
      console.log(error);

      const errorMessage = String(error?.response?.data?.message).toLowerCase();

      if (errorMessage.includes("not found"))
        form.setError("email", {
          type: "manual",
          message: String(t("auth.user_not_found")),
        });

      if (errorMessage.includes("not correct"))
        form.setError("password", {
          type: "manual",
          message: String(t("auth.password_not_correct")),
        });
    }

    setIsLoading(false);
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <div className="flex flex-col justify-center">
        <p className="text-2xl font-medium">{t("auth.login_title")}</p>
        <p className="text-base font-medium text-gray-500">
          {t("auth.login_subtitle")}
        </p>
      </div>
      <div className="mt-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.email")}</FormLabel>
                      <FormControl>
                        <Input placeholder={t("auth.email")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-2 mb-4">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.password")}</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder={t("auth.password")}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Link
                  to={getLink(LINK_TYPE.FORGOT_PASSWORD)}
                  className="inline-block text-sm underline font-medium mt-4 text-gray-600"
                >
                  {t("auth.forgot_password")}
                </Link>
              </div>
              <div className="flex justify-between items-center">
                <div className="mt-1 text-sm">
                  {t("auth.register_ask")}{" "}
                  <Link
                    to={getLink(LINK_TYPE.REGISTER)}
                    className="underline font-medium"
                  >
                    {t("auth.register")}
                  </Link>
                </div>
                <Button
                  size={"sm"}
                  disabled={isLoading}
                  type="submit"
                  variant="takyon"
                >
                  {t("auth.login_button")}
                </Button>
              </div>
              {/* <Button variant="outline" className="w-full">
                Login with Google
              </Button> */}
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
