import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import getLink, { LINK_TYPE } from "@/lib/links";
import { H2 } from "../typography/Typography";
import { sendRecoveryPasswordEmail } from "@/services/auth";
import { ArrowLeft } from "lucide-react";

export function ForgotPasswordForm() {
  const { t } = useTranslation();

  const formSchema = z.object({
    email: z.string().email({
      message: t("auth.invalid_email"),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // Track if the email was successfully sent

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const resetPasswordLink =
        getLink(LINK_TYPE.RESET_PASSWORD, {}, "DASHBOARD_FULL") +
        "?token=__TOKEN__";

      // console.log("resetPasswordLink", resetPasswordLink);

      const payload = {
        ...values,
        redirect: resetPasswordLink,
      };
      // Simulate email sending process
      await sendRecoveryPasswordEmail(payload);
      setEmailSent(true); // Email sent successfully
    } catch (error: any) {
      form.setError("email", {
        type: "manual",
        message: t("auth.invalid_email_password"),
      });
    }
    setIsLoading(false);
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <div className="flex items-center justify-center h-full">
        <p className="text-2xl flex-1 font-medium">
          {t("auth.forgot_password_title")}
        </p>
      </div>
      <div className="mt-5">
        {!emailSent ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid gap-4">
                <div className="grid gap-2 mb-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("auth.email")}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={t("auth.email")}
                            {...field}
                            disabled={isLoading} // Disable input during loading
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <p className="text-xs text-gray-500">
                    {t("auth.forgot_password_text")}
                  </p>
                </div>
                <div className="flex justify-between">
                  <Button
                    type="button"
                    size={"sm"}
                    variant={"outline"}
                    onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
                  >
                    {t("auth.back")}
                  </Button>
                  <Button
                    type="submit"
                    size={"sm"}
                    variant={"takyon"}
                    disabled={isLoading}
                  >
                    {isLoading ? t("auth.sending") : t("auth.send")}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        ) : (
          // Success message and return to login button
          <div className="text-center">
            <p className="mb-4">{t("auth.reset_email_sent")}</p>
            <Button
              className="w-full"
              variant={"takyon"}
              onClick={() => navigate(getLink(LINK_TYPE.LOGIN))} // Navigate to login page
            >
              {t("auth.return_to_login")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
