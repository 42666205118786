import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { X } from "lucide-react";
import { signal } from "@preact/signals-react";
import { Separator } from "@/components/ui/separator";
import { useEffect } from "react";
import LoyaltyTerms from "./Loyalty_terms";
import LoyaltyBilling from "./loyalty_billing";
import LoyaltyHowWorks from "./loyalty_how_works";

const currentStep = signal(1);

export default function LoyaltyContainer({ onRefresh }: { onRefresh(): any }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { collectionId } = useParams();

  // Update the current step based on the URL path using useEffect
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/loyalty/billing")) {
      currentStep.value = 2;
    } else if (path.includes("/loyalty/terms")) {
      currentStep.value = 3;
    } else {
      currentStep.value = 1;
    }
  }, [location.pathname]);

  // Determine which component to render based on the URL path
  const renderContent = () => {
    const path = location.pathname;

    if (path.includes("/loyalty/terms")) {
      return <LoyaltyTerms onRefresh={onRefresh} />;
    } else if (path.includes("/loyalty/billing")) {
      return <LoyaltyBilling />;
    } else {
      return <LoyaltyHowWorks onRefresh={onRefresh} />;
    }
  };

  const handleExit = () => {
    if (collectionId) {
      navigate(`/collection/${collectionId}/analytics`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col px-6 py-10 md:px-14 md:py-8 min-h-[calc(100dvh-theme(spacing.16)-theme(spacing.16))]">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-medium">{t("loyalty.title")}</p>
          </div>
          <Button variant="default" onClick={handleExit}>
            {t("loyalty.exit")}
            <X size={16} className="ml-2" />
          </Button>
        </div>
        <p className="text-base text-gray-600 font-medium">
          {t("loyalty.step")} {currentStep.value}{" "}
          <span>{t("loyalty.of")} </span>3
        </p>
        <Separator className="my-6" />
      </div>
      {/* Content */}
      <div className="flex-1">{renderContent()}</div>
    </div>
  );
}
