import { RenderDocument } from "@/components/documents/RenderDocument";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Skeleton } from "@/components/ui/skeleton";
import { cosent } from "@/services/auth";
import {
  getCollection,
  iCollection,
  patchCollection,
} from "@/services/collection";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function LoyaltyTerms({ onRefresh }: { onRefresh(): any }) {
  const { collectionId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [takyonCodeAccepted, setTakyonCodeAccepted] = useState(false);
  const [collection, setCollection] = useState<iCollection | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBack = () => {
    navigate(`/collection/${collectionId}/loyalty`);
  };

  const completeLoyalty = async () => {
    if (!collectionId && !collection) return;

    try {
      setIsLoading(true);
      const updatedCollection = await patchCollection({
        isLoyaltyEnabled: true,
        collectionId: collectionId,
      });

      if (updatedCollection) {
        await cosent({
          email: collection?.census?.email,
          target: "loyalty-tos",
          consent: true,
        });

        await onRefresh();

        navigate(`/collection/${collectionId}/analytics`, {
          state: { openModalLoyalty: true },
        });
      }
    } catch (error) {
      console.error("Error completing loyalty:", error);
    }
  };

  // Function to load collection data
  const loadCollection = useCallback(async () => {
    if (!collectionId) return;

    try {
      setIsLoading(true);
      const response = await getCollection(collectionId);
      setCollection(response);
    } catch (error) {
      console.error("Error loading collection:", error);
    } finally {
      setIsLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  if (isLoading) {
    return (
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div className="overflow-auto mb-12" style={{ height: "45vh" }}>
          <RenderDocument name="dashboard_loalty_tos" />
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="terms"
            checked={termsAccepted}
            onCheckedChange={(checked) => setTermsAccepted(checked === true)}
          />
          <p className="text-sm font-medium">{t("loyalty.accept_terms")}</p>
        </div>
        <div className="flex flex-col space-y-3 mt-2 mb-10">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="takyon_code"
              checked={takyonCodeAccepted}
              onCheckedChange={(checked) =>
                setTakyonCodeAccepted(checked === true)
              }
            />
            <p className="text-sm font-medium">
              {t("loyalty.accept_takyon_code")}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between pt-4">
        <Button variant="outline3" className="mt-4" onClick={handleBack}>
          {t("buttons.back")}
        </Button>
        <Button
          variant="takyon"
          type="button"
          className="mt-4"
          disabled={!termsAccepted || !takyonCodeAccepted}
          onClick={completeLoyalty}
        >
          {t("loyalty.activate")}
        </Button>
      </div>
    </div>
  );
}
