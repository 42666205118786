import axios from "axios";

import { iPaginationProps, Pagination } from "../utils/Pagination";
import { LANG } from "./i18next";
import { member, role } from "./member";
import { iUser } from "./auth";
import helpers from "@/components/helpers/helpers";
import { iCollectionAlias } from "./alias";

export enum COLLECTION_TYPE {
  experience = "experience",
  hotel = "hotel",
}

export enum COLLECTION_PERMISSIONS {
  admin = "admin",
  update = "update",
  memebers = "memebers",
  census = "census",
  preset = "preset",
  create_nft = "create_nft",
  update_nft = "update_nft",
  delete_nft = "delete_nft",
  sale = "sale",
  set_payment_method = "set_payment_method",
  view_payment_data = "view_payment_data",
  receive_notifications = "receive_notifications",
  stealth = "stealth",
  view_events = "view_events",
}

export type experienceCensusInfo = {
  [lang in LANG]?: {
    experiences?: {
      name: string;
      description: string;
      images: string[];
    }[];
  };
};

export type hotelCensusInfo = {
  [lang in LANG]?: {
    description?: string;
    services?: { icon: string; value: string }[];
    rooms?: {
      name: string;
      services?: { icon: string; value: string; data: string }[];
      traits?: { icon: string; value: string }[];
      images?: string[];
    }[];
    poi?: {
      icon: string;
      value: string;
      text: string;
    }[];
    policies?: {
      title: string;
      text: string;
    }[];
    reviews?: {
      vote?: number;
      voteText?: string;
      date?: string;
      context?: string;
      title: string;
      text: string;
    }[];
    reviewSummary?: {
      vote?: number;
      voteText?: string;
      cleanlinessVote?: number;
      hotelConditionVote?: number;
      roomConfortVote?: number;
      serviceStaffVote?: number;
    };
  };
};

export type hotelCensusLocationType =
  | "sea"
  | "mountain"
  | "city"
  | "lake"
  | "wellness";

export type hotelCensus = {
  type?: "hotel";

  name?: string;
  stars?: string;
  location?: any;
  locationType?: hotelCensusLocationType;
  email?: string;
  website?: string;
  firstName?: string;
  secondName?: string;
  note?: string; // deprecated
  images?: Array<string>;
  groupName?: string;
  info?: hotelCensusInfo;
  hotelsComSlug?: string;
  hotelsComId?: string;
  feeOnCreation?: number;
  feeMonthly?: number;
  feeFixedOnCreation?: number;
  cin?: string;
  cir?: string;
};

export type hotelStats = {
  averageBookingPrice?: number;
  averageNumberOfDaysPerStay?: number;
  averageOccupancyPercentage?: number;
  directBookingPercentage?: number;
  nonRefoundableSalesPercentage?: number;
};

// Exp - start
/*
export type experienceCategory =
  | "sport"
  | "adrenaline"
  | "city"
  | "wellness";
*/

export enum ExperienceCategory {
  sport = "Sport",
  adrenaline = "Adrenaline",
  city = "City",
  wellness = "Wellness",
}

export type experienceCensus = {
  type?: "experience";

  name?: string;
  email?: string;
  website?: string;
  location?: any;

  category?: ExperienceCategory;

  // firstName?: string;
  // secondName?: string;
  note?: string; // deprecated
  // images?: Array<string>;

  // questo tipo non ha bisogno di queste proprietà...
  // stars?: string;
  // info?: null;
  // hotelsComSlug?: string;
  // hotelsComId?: string;
};

// Exp - end

export type publicContact = {
  type: "phone" | "website" | "whatsapp" | "email";
  value: string;
};

export interface iCollection {
  _id: string;
  name: string;
  groupId: string;
  gold: boolean; // TODO deprecable?
  type: string;
  paymentMethodId: string;
  members: Array<member<COLLECTION_PERMISSIONS>>;

  censusStats?: hotelStats;
  census?: hotelCensus;
  images: Array<string>;

  nftCount?: number;
  lastNftCreatedAt?: any;
  subscriptionStatus?: string;
  sellerPaymentStatus?: boolean;

  feeOnCreation?: number;
  feeMonthly?: number;
  feeFixedOnCreation?: number;

  trialEndDate?: Date;

  bonusCredits?: number;

  isActive: boolean;

  checkBeforeSale?: boolean;

  liveDate?: any;
  valueGenerated?: number;

  _createdAt: any;

  alias?: iCollectionAlias;

  dataAnalysisMaskId?: string;

  publicContacts?: publicContact[];

  preData?: {
    bookingEngineUrlRegex?: string;
    conversionRate?: number;
    otaFee?: number;
    bookingComId?: string;
    bestRateOnOtaType?: "refundable" | "non-refundable";
    bookingEngine: string;
    bookingEngineParams?: {
      hotelId?: string;
      channelId?: string;
      otherId?: string;
    };
    deltaPriceBookingCom: number;
    deltaPriceHotelsCom: number;
    advantagesOnDirectWebsite: string[];
  };

  isFree?: boolean;
  scriptEnabled: boolean;
  analyticsVisible?: boolean;
  startTrackingDate?: Date;
  isOnboardingComplete?: boolean;
  isLoyaltyEnabled?: boolean;
  loyatyDiscountPercentage?: number;
  loyatyCPAPerNight?: number;
}

export const collectionRoles: role<COLLECTION_PERMISSIONS>[] = [
  {
    name: "moderator",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.create_nft,
      COLLECTION_PERMISSIONS.delete_nft,
      COLLECTION_PERMISSIONS.update_nft,
      COLLECTION_PERMISSIONS.set_payment_method,
      COLLECTION_PERMISSIONS.view_payment_data,
      COLLECTION_PERMISSIONS.census,
      COLLECTION_PERMISSIONS.receive_notifications,
      COLLECTION_PERMISSIONS.sale,
      COLLECTION_PERMISSIONS.view_events,
    ],
    onlyAdmin: true,
  },
  {
    name: "rm",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.set_payment_method,
      COLLECTION_PERMISSIONS.view_payment_data,
      COLLECTION_PERMISSIONS.census,
      COLLECTION_PERMISSIONS.receive_notifications,
      COLLECTION_PERMISSIONS.sale,
      COLLECTION_PERMISSIONS.view_events,
    ],
  },
  {
    name: "rmnopayments",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.memebers,
      COLLECTION_PERMISSIONS.census,
      COLLECTION_PERMISSIONS.receive_notifications,
      COLLECTION_PERMISSIONS.sale,
      COLLECTION_PERMISSIONS.view_events,
    ],
  },
  {
    name: "editor",
    permissions: [
      COLLECTION_PERMISSIONS.update,
      COLLECTION_PERMISSIONS.create_nft,
      COLLECTION_PERMISSIONS.update_nft,
      COLLECTION_PERMISSIONS.census,
    ],
    onlyAdmin: true,
  },
  {
    name: "notificable",
    permissions: [COLLECTION_PERMISSIONS.receive_notifications],
  },
  {
    name: "api",
    permissions: [
      COLLECTION_PERMISSIONS.create_nft,
      COLLECTION_PERMISSIONS.update_nft,
    ],
    onlyAdmin: true,
  },
  {
    name: "member",
    permissions: [],
  },
  {
    name: "stealth_events",
    permissions: [
      COLLECTION_PERMISSIONS.stealth,
      COLLECTION_PERMISSIONS.view_events,
    ],
    onlyAdmin: true,
  },
  {
    name: "stealth_editor",
    permissions: [
      COLLECTION_PERMISSIONS.stealth,
      COLLECTION_PERMISSIONS.preset,
      COLLECTION_PERMISSIONS.census,
      COLLECTION_PERMISSIONS.update,
    ],
    onlyAdmin: true,
  },
];

export interface Destination {
  dest_id: string;
  search_type: string;
  image_url: string;
  country: string;
  label: string;
  region: string;
  type: string;
  nr_hotels: number;
  name: string;
  latitude: number;
  cc1: string;
  hotels: number;
  dest_type: string;
  city_name: string;
  lc: string;
  roundtrip: string;
  longitude: number;
  city_ufi: string | null;
}

export interface loadCollectionProps {
  page?: number;
  size?: number;
  total?: number;
  groupId?: string;
  gold?: boolean;
  query?: any;
  sort?: any;
}

export interface iPagination<T> {
  data: T[];
  total: number;
  page: number;
  size: number;
}

// WIP - new function for fetching collections
// in DataView component that use react-table
export async function fetchCollections({
  page = 1,
  size = 25,
  groupId = "",
  gold = false,
  query = {},
  sort = { name: 1 },
}): Promise<iCollection[]> {
  let url = `/collection/paginate?page=${page}&size=${size}`;

  if (groupId) {
    url += "&groupId=" + groupId;
  }

  if (typeof gold === "boolean") {
    if (gold) url += "&gold=true";
    else url += "&gold=false";
  }

  if (query) {
    url += "&query=" + JSON.stringify(query);
  }

  if (sort) {
    url += "&sort=" + JSON.stringify(sort);
  }

  const response = await axios.get(url);
  return response.data;
}

export function inviteNotAccepted(collection: iCollection, user: iUser) {
  return (
    collection.members.find((e) => e.email === user?.email)
      ?.isInviteAccepted === false
  );
}

export async function getCollections(
  params: iPaginationProps
): Promise<Pagination<iCollection>> {
  const response = await axios.get("/collection/paginate", { params });
  return response.data;
}

export async function getCollectionsInvitations(): Promise<iCollection[]> {
  const response = await axios.get("/collection/invitations");
  return response.data;
}

export async function getCollectionsAliases(
  params: iPaginationProps
): Promise<Pagination<iCollection>> {
  const response = await axios.get("/collection/alias/paginated", {
    params,
  });
  return response.data;
}

export async function createCollection(payload: any): Promise<iCollection> {
  const response = await axios.post("/collection", payload);
  return response.data;
}

export async function patchCollection(payload: any): Promise<iCollection> {
  const response = await axios.patch("/collection", payload);
  return response.data;
}

export async function getCollection(id: string): Promise<iCollection> {
  const response = await axios.get("/collection/" + id);
  return response.data;
}

// seems to be duplicated
export async function updateCollection(payload: any): Promise<iCollection> {
  const response = await axios.patch("/collection/", payload);
  return response.data;
}

export async function BookingComSearchRegions(params: {
  query: string;
}): Promise<Destination[]> {
  const res = await axios.post("/metasearch/regions", params);
  return res.data;
}

export async function inviteMemberToCollection(
  payload: any
): Promise<iCollection> {
  const response = await axios.post("/collection/member", payload);
  return response.data;
}

export async function acceptInviteCollection(payload: any) {
  const response = await axios.post("/collection/join", payload);
  return response.data;
}

export async function declineInviteCollection(payload: any) {
  const response = await axios.post("/collection/decline", payload);
  return response.data;
}

export async function removeMemberFromCollection(payload: any): Promise<any> {
  const response = await axios.delete("/collection/member", { data: payload });
  return response.data;
}

export async function setCollectionPermissionsPerUser(payload: {
  collectionId: string;
  email: string;
  permissions: COLLECTION_PERMISSIONS[];
}): Promise<any> {
  const response = await axios.patch("/collection/member", payload);
  return response.data;
}

export async function leaveCollection(payload: any) {
  const response = await axios.post("/collection/leave", payload);
  return response.data;
}

export async function deleteCollection(payload: any) {
  const response = await axios.delete("/collection", { data: payload });
  return response.data;
}

export async function transferCollection(payload: any) {
  const response = await axios.patch("/collection/transfer-ownership", payload);
  return response.data;
}

export async function setTrialDate(payload: any) {
  const response = await axios.post("/collection/trial", payload);
  return response.data;
}

export async function getCollectionCensusInfoHotelsCom(payload: any) {
  const response = await axios.post("/hotelscom/all-info", payload);
  return response.data;
}

export async function getCollectionCensusInfoRoomsHoltelsCom(payload: any) {
  const response = await axios.post("/hotelscom/rooms", payload);
  return response.data;
}

export async function getCollectionCensusInfoReviewsHoltelsCom(payload: any) {
  const response = await axios.post("/hotelscom/reviews", payload);
  return response.data;
}

export async function getPaginated(
  params: iPaginationProps
): Promise<iPagination<iCollection>> {
  const response = await axios.get("/collection/paginate-public", {
    params,
  });
  return response.data;
}

export async function autofillCollectionCensusInfo(
  collection: iCollection,
  lang: LANG,
  override = false
): Promise<hotelCensusInfo> {
  let info: hotelCensusInfo = {};

  if (collection.census?.type !== "hotel") return {};

  if (!collection.census) return info;
  if (!collection.census.hotelsComSlug && !collection.census.hotelsComId)
    return info;

  if (collection.census?.info)
    info = JSON.parse(JSON.stringify(collection.census.info));

  Object.values(LANG).forEach((lang) => {
    if (!info[lang]) info[lang] = {};

    if (!info[lang]?.services) info[lang]!.services = [];
    if (!info[lang]?.rooms) info[lang]!.rooms = [];
    if (!info[lang]?.poi) info[lang]!.poi = [];
    if (!info[lang]?.policies) info[lang]!.policies = [];
    if (!info[lang]?.reviews) info[lang]!.reviews = [];
    if (!info[lang]?.reviewSummary) info[lang]!.reviewSummary = {};
  });

  const hotelInfo = await getCollectionCensusInfoHotelsCom({
    lang: lang,
    hotelsComId: collection.census.hotelsComId,
    hotelsComSlug: collection.census.hotelsComSlug,
  });
  const description =
    hotelInfo.info?.propertyContentSectionGroups.aboutThisProperty.sections[0]
      .bodySubSections[0].elements[0].items[0].content.text;
  if (description && (override || !info[lang]?.description))
    info[lang]!.description = description;

  const services =
    hotelInfo.summary.summary?.amenities?.topAmenities?.items?.map(
      (service: any) => ({
        icon: service.icon?.token,
        value: service.text,
      })
    );
  if (
    services &&
    (override || !info[lang]?.services || !info[lang]?.services?.length)
  )
    info[lang]!.services = services;

  const poi = hotelInfo.details.summary?.nearbyPOIs?.items?.map((poi: any) => ({
    icon: poi.icon?.token,
    value: poi.text,
    text: poi.moreInfo,
  }));
  if (poi && (override || !info[lang]?.poi || !info[lang]?.poi?.length))
    info[lang]!.poi = poi;

  const policies: any[] = [];
  const propertyPolicies = hotelInfo.details.summary?.policies;
  for (const key in propertyPolicies) {
    if (propertyPolicies.hasOwnProperty(key)) {
      const policy = propertyPolicies[key];
      const title = policy?.title || key;

      if (policy?.body && policy.body.length > 0) {
        const textListBody = policy.body.map((item: any) => `${item}`);

        policies.push({
          title: title,
          text: `${textListBody.join(", ")}`,
        });
      } else if (Array.isArray(policy) && policy.length > 0) {
        const textList = policy.map((item: any) => `${item}`);
        policies.push({
          title: title,
          text: `${textList.join(", ")}`,
        });
      }
    }
  }
  if (
    policies &&
    (override || !info[lang]?.policies || !info[lang]?.policies?.length)
  )
    info[lang]!.policies = policies;

  const roomsInfo = await getCollectionCensusInfoRoomsHoltelsCom({
    lang: lang,
    hotelsComId: collection.census.hotelsComId,
    hotelsComSlug: collection.census.hotelsComSlug,
  });
  const rooms = roomsInfo.rooms.units.map((unit: any) => {
    return {
      name: unit?.header?.text,

      images: unit?.unitGallery?.gallery?.map(
        (imageObj: any) => imageObj?.image?.url
      ),

      services: unit?.roomAmenities?.bodySubSections?.[0].contents?.map(
        (amenity: any) => {
          return {
            value: amenity?.header?.text,
            icon: amenity?.header?.icon?.token,
            data: amenity?.items[0].content.text,
          };
        }
      ),

      traits: unit?.features?.map((feature: any) => {
        return {
          value: feature?.text,
          icon: feature?.graphic?.token,
        };
      }),
    };
  });
  if (rooms && (override || !info[lang]?.rooms || !info[lang]?.rooms?.length))
    info[lang]!.rooms = rooms;

  const { reviewsSummary, reviews } =
    await getCollectionCensusInfoReviewsHoltelsCom({
      lang: lang,
      hotelsComId: collection.census.hotelsComId,
      hotelsComSlug: collection.census.hotelsComSlug,
    });

  if (
    reviewsSummary &&
    (override || info[lang]?.reviewSummary || !info[lang]?.reviewSummary?.vote)
  )
    info[lang]!.reviewSummary = {
      vote: Number(Number(reviewsSummary.averageOverallRating.raw).toFixed(2)),
      voteText: `${Number(reviewsSummary.averageOverallRating.raw).toFixed(
        1
      )}/10`,
      cleanlinessVote: Number(
        Number(reviewsSummary.cleanliness.raw).toFixed(2)
      ),
      hotelConditionVote: Number(
        Number(reviewsSummary.hotelCondition.raw).toFixed(2)
      ),
      roomConfortVote: Number(
        Number(reviewsSummary.roomComfort.raw).toFixed(2)
      ),
      serviceStaffVote: Number(
        Number(reviewsSummary.serviceAndStaff.raw).toFixed(2)
      ),
    };

  if (
    reviews &&
    (override || !info[lang]?.reviews || !info[lang]?.reviews?.length)
  )
    info[lang]!.reviews = reviews.map((r: any) => {
      let date: any;

      try {
        date = new Date(r.submissionTimeLocalized);
      } catch (error) {}

      return {
        vote: Number(String(r.reviewScoreWithDescription.value).split("/")[0]),
        voteText: r.reviewScoreWithDescription.accessibilityLabel,
        date: date,
        context: r.stayDuration,
        title: r.title,
        text: r.text,
      };
    });

  return info;
}

export interface iCreateFakeHotelSupply {
  collectionId: string;
  guestsAmount: number;
  guestsKidsAmount: number;
  nightsAmount: number;
  startFromDate: string;
  roomType: string;
  owner: string;
  putForSale: boolean;
  discountFromMarket: number;
  price: number;
}

export async function createFakeHotelSupply(payload: iCreateFakeHotelSupply) {
  //console.log(payload);
  const respones = await axios.post(
    "/collection/create-hotel-artificial-supply",
    payload
  );
  return respones.data;
}

export async function sendOnboardingEmail(id: string): Promise<iCollection> {
  const response = await axios.post("/collection/send-onboarding-email", {
    collectionId: id,
  });
  return response.data;
}

export function onBoardingCompleted(collection: iCollection) {
  try {
    if (collection.isOnboardingComplete) return true;

    if (
      !collection.census ||
      !collection.preData ||
      !collection.publicContacts
    ) {
      // console.log(
      //   `Collection ${collection._id} - ${collection.name}`,
      //   "Collection census is missing"
      // );
      return false;
    }

    const hasDetails = helpers.hasRequiredProps(collection, [
      "census.name",
      "census.website",
      "census.location",
      "census.groupName",
      "preData.deltaPriceBookingCom",
      "preData.deltaPriceHotelsCom",
    ]);

    const hasbookingComId = helpers.hasRequiredProps(collection, [
      "preData.bookingComId",
    ]);

    if (hasbookingComId === false) {
      const createdAt = new Date(collection._createdAt);
      const prev2025 = new Date("2025-02-01T00:00:00Z");
      return createdAt < prev2025;
    }

    // const hasAtLeastOneNotSuperAdminMember = collection.members.some((m) =>
    //   m.permissions.some((p) => p !== COLLECTION_PERMISSIONS.admin)
    // );

    // const hasAtLeastOneImage = collection.images?.length > 0;

    //
    // const paymentIsRequired = !collection.isFree && !collection.paymentMethodId;

    // console.log(`Collection ${collection._id} - ${collection.name}`, {
    //   hasDetails,
    //   hasAtLeastOneNotSuperAdminMember,
    //   hasAtLeastOneImage,
    //   collection,
    //   isFree: collection.isFree,
    //   paymentIsRequired: paymentIsRequired,
    // });

    return hasDetails;
    // hasAtLeastOneNotSuperAdminMember &&
    // hasAtLeastOneImage &&
    // !paymentIsRequired
  } catch (error) {
    console.log(error);
  }
}

export function getCollectionSettings(collection: iCollection) {
  return {
    trialEndDate: collection.trialEndDate,
    feeOnCreation: collection.feeOnCreation,
    feeMonthly: collection.feeMonthly,
    feeFixedOnCreation: collection.feeFixedOnCreation,
    bonusCredits: collection.bonusCredits,
  };
}

export function onBoardingLastStep(collection: iCollection) {
  let step = "selecthotel";

  if (!collection?.census?.groupName) step = "hotelchain";
  if (!collection?.preData?.advantagesOnDirectWebsite) step = "hotelbenefits";
  if (
    !collection?.preData?.deltaPriceBookingCom ||
    !collection?.preData?.deltaPriceHotelsCom
  )
    step = "hotelprice";

  if (collection?.preData?.bookingComId) step = "hoteldata";
  // if (collection?.publicContacts) step = "hotelprice";
  // if (collection?.preData?.deltaPriceBookingCom) step = "hotelbenefits";
  // if (collection?.preData?.advantagesOnDirectWebsite) step = "hotelchain";
  if (!collection?.publicContacts || collection?.publicContacts.length < 3)
    step = "hoteldata";
  if (!collection?.census) step = "hoteldata";
  if (!collection?.census?.location) step = "hoteldata";
  if (
    !collection?.name ||
    !collection?.census?.name ||
    !collection?.preData?.bookingComId
  )
    step = "selecthotel";

  return step;
}
