import axios from "axios";

import { Pagination } from "../utils/Pagination";

export interface iDocument {
  _id: string;
  name: string;
  text: string;
  lang: string;
  type: string;
}

export async function editDocument(payload: any) {
  const response = await axios.post("/docs", payload);
  return response.data;
}

export async function deleteDocument(payload: any) {
  const response = await axios.delete("/docs", {
    data: payload,
  });
  return response.data;
}

export async function getDocumentsPaginated({
  page = 1,
  size = 25,
}): Promise<Pagination<iDocument>> {
  const url = "/docs/paginate";

  const response = await axios.get(url, {
    params: {
      page,
      size,
    },
  });
  return response.data;
}

export async function getDocumentsByName(name: string): Promise<iDocument[]> {
  const response = await axios.get(`/docs/${name}`);
  return response.data;
}
