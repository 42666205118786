import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CollectionPageChildProps } from "../CollectionPage";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import {
  BookingComSearchRegions,
  Destination,
  getPaginated,
  patchCollection,
} from "@/services/collection";
import { getGroup, patchGroup } from "@/services/groups";
import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";

export default function SelectHotel({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [params, setParams] = useState({
    dest_label: collection?.census?.name || "",
    dest_id: collection?.preData?.bookingComId || "",
    dest_name: collection?.census?.name || "",
    search_type: collection?.census?.type || "",
    latitude: "",
    longitude: "",
  });
  const [locations, setLocations] = useState<Destination[]>([]);
  const [alreadyExist, setAlreadyExist] = useState(false);

  const searchLocation = async (query: string) => {
    setIsSelectLoading(true);
    try {
      const data = await BookingComSearchRegions({
        query,
      });
      // List of European country codes
      const europeanCountryCodes = [
        "at",
        "be",
        "bg",
        "hr",
        "cy",
        "cz",
        "dk",
        "ee",
        "fi",
        "fr",
        "de",
        "gr",
        "hu",
        "ie",
        "it",
        "lv",
        "lt",
        "lu",
        "mt",
        "nl",
        "pl",
        "pt",
        "ro",
        "sk",
        "si",
        "es",
        "se",
        "gb",
        "ch",
        "no",
        "is",
        "li",
        "mc",
        "sm",
        "va",
        "ad",
        "me",
        "mk",
        "rs",
        "al",
      ];

      // Filter for hotels, and in production also filter for European countries
      const filteredHotels = data.filter(
        (item: Destination) =>
          item.search_type === "hotel" &&
          (import.meta.env.PROD
            ? europeanCountryCodes.includes(item.cc1.toLowerCase())
            : true)
      );
      // Sort results to put Italian hotels at the top
      const sortedHotels = filteredHotels.sort((a, b) => {
        // If a is Italian and b is not, a comes first
        if (a.cc1.toLowerCase() === "it" && b.cc1.toLowerCase() !== "it") {
          return -1;
        }
        // If b is Italian and a is not, b comes first
        if (b.cc1.toLowerCase() === "it" && a.cc1.toLowerCase() !== "it") {
          return 1;
        }
        // Otherwise maintain original order
        return 0;
      });
      setLocations(sortedHotels);
      setIsSelectLoading(false);
    } catch (error) {
      console.log(error);
    }
    setIsSelectLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(searchLocation, 1000));

  useEffect(() => {
    if (params?.dest_label == "") {
      setParams((prev) => ({ ...prev, dest_id: "" }));
    }
  }, [params?.dest_label]);

  useEffect(() => {
    setBackBtn(true);
    setProgress(20);
  }, []);

  const checkAlreadyExist = async (dest_id: string) => {
    try {
      const result = await getPaginated({
        page: 1,
        size: 1,
        query: JSON.stringify({ "preData.bookingComId": dest_id }),
      });
      if (result.data.length > 0) {
        setAlreadyExist(true);
        setParams({
          dest_label: "",
          dest_name: "",
          dest_id: "",
          search_type: "",
          latitude: "",
          longitude: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (params?.dest_name == "") return;
    setIsLoading(true);
    try {
      const group = await getGroup(collection?.groupId || "");
      if (
        group.name === "Gruppo senza nome" ||
        group.name === "Unnamed group" ||
        group.name === "Grupo sin nombre"
      ) {
        await patchGroup({
          groupId: collection?.groupId,
          name: params?.dest_name,
        });
      }
      await patchCollection({
        collectionId: collection?._id,
        name: params?.dest_name,
        census: {
          ...collection?.census,
          type: "hotel",
          name: params?.dest_name,
        },
        preData: {
          ...collection?.preData,
          bookingComId: params?.dest_id,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      await onRefresh();
      navigate(`/collection/${collection?._id}/infos/hoteldata`);
    }

    setIsLoading(false);
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <div className="mt-5 mb-10">
          <p className="text-base font-medium">{t("onboarding.step")} 1</p>
          <p className="text-2xl font-medium">{t("onboarding.select_hotel")}</p>
        </div>
        <div className="relative w-full md:w-2/5">
          <p className="text-sm font-medium mb-2">
            {t("onboarding.findDestination")}
          </p>
          <input
            type="text"
            placeholder={t("onboarding.destination")}
            value={params?.dest_label}
            onChange={(e) => {
              setAlreadyExist(false);
              setParams({ ...params, dest_label: e.target.value });
              setIsLocationOpen(true);
              if (e.target.value.length >= 2) {
                setIsSelectLoading(true);
                searchCallbackDebounced.current(e.target.value);
              }
            }}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-takyon"
          />
          {alreadyExist && (
            <p className="text-xs font-medium mt-1 text-red-500">
              {t("onboarding.alreadyExist")}
            </p>
          )}
          {params?.dest_label && (
            <button
              onClick={() => {
                setParams({ ...params, dest_label: "" });
                setLocations([]);
              }}
              className="absolute right-3 top-1/2 -translate-y-1/2"
            >
              <div className="h-4 w-4 text-gray-500"></div>
            </button>
          )}
          {isLocationOpen && params?.dest_label.length >= 2 && (
            <div className="absolute mt-1 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto z-50">
              {isSelectLoading ? (
                <div className="p-2 space-y-2">
                  <div className="h-5 bg-gray-100 rounded animate-pulse" />
                  <div className="h-5 bg-gray-100 rounded animate-pulse" />
                  <div className="h-5 bg-gray-100 rounded animate-pulse" />
                </div>
              ) : locations.length > 0 ? (
                <div className="py-1">
                  {locations.map((location) => (
                    <button
                      key={location.dest_id}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        checkAlreadyExist(location.dest_id);
                        setParams({
                          ...params,
                          dest_label: location.label,
                          dest_id: location.dest_id,
                          dest_name: location.name,
                          search_type: location.dest_type,
                          latitude: "",
                          longitude: "",
                        });
                        setIsLocationOpen(false);
                      }}
                    >
                      <div>
                        <div className="text-sm font-medium">
                          {location.label}
                        </div>
                        <div className="text-xs text-gray-500">
                          {location.city_name || location.label}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              ) : (
                <div className="p-4 text-center text-gray-500">
                  {t("onboarding.no_results")}
                </div>
              )}
            </div>
          )}
          <p className="text-sm text-gray-500 font-medium mt-6">
            {t("onboarding.cantFIndIt")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => (
                fireJuneEvent("onboarding_contactus"),
                (window.location.href = "mailto:partner.support@takyon.io")
              )}
            >
              {t("onboarding.contactus")}
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col mt-10">
        <div className="flex flex-row justify-end">
          {!isLoading ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={(e: any) => onSubmit(e)}
              disabled={!params?.dest_id}
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button disabled className="min-w-40">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("buttons.loading")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
