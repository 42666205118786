import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartConfig,
} from "@/components/ui/chart";
import {
  impressionResponse,
  MaskDataResponse,
} from "@/services/dataanalysismask";
import { formatNumberEuropean } from "@/utils/generic";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Cone } from "lucide-react";
import { CartesianGrid, XAxis, Area, AreaChart } from "recharts";
import React from "react";

export default function Impression({ data }: { data: MaskDataResponse }) {
  const { i18n } = useTranslation();

  const impressionConfig = {
    amount: {
      label: t("ds.impression"),
      color: "#8CE786",
    },
  } satisfies ChartConfig;

  const impressionData = data.impression as impressionResponse;

  if (!impressionData.data) return null;

  // const getLastNElements = (data: typeof impressionData.data, n: number) => {
  //   return data.slice(-n).map((item) => ({
  //     ...item,
  //     date: `${item.year}-${String(item.month + 1).padStart(2, "0")}-${String(
  //       item.day
  //     ).padStart(2, "0")}`,
  //     [impressionConfig.amount.label]: item.impressionAmount,
  //   }));
  // };

  // const filteredData = getLastNElements(impressionData.data, Number(period));

  const impressionDataWithDate = impressionData.data.map((item) => ({
    ...item,
    date: `${item.year}-${String(item.month + 1).padStart(2, "0")}-${String(
      item.day
    ).padStart(2, "0")}`,
  }));

  const CustomLegend = React.forwardRef<HTMLDivElement>((props: any, ref) => {
    const { payload } = props;
    if (!payload?.length) return null;

    return (
      <div ref={ref} className="w-full flex justify-center gap-2 mt-2">
        <div className="flex items-center gap-2">
          <div
            className="w-2 h-2 rounded-xs"
            style={{ backgroundColor: impressionConfig.amount.color }}
          />
          <span className="text-xs">{t("ds.impression")}</span>
        </div>
      </div>
    );
  });

  return (
    <div className="w-full border rounded-xl flex flex-col shadow-md">
      <div className="w-full flex justify-between border-b">
        <div className="flex flex-col py-3 px-5 gap-1">
          <div className="flex align-items-center gap-2">
            <Cone></Cone>
            <p className="text-xl font-medium">{t("ds.impression")}</p>
          </div>
          <p className="text-sm font-medium text-gray-400">
            {t("ds.impression_description")}
          </p>
        </div>
        <div className="flex flex-col py-3 px-5 border-l bg-gray-100">
          <p className="text-sm font-medium">{t("ds.impression")}</p>
          <p className="text-3xl font-semibold">
            {formatNumberEuropean(Number(impressionData?.total?.toFixed(0)), 0)}
          </p>
        </div>
      </div>
      <ChartContainer
        className="p-4 h-[440px] w-full aspect-auto"
        config={impressionConfig}
      >
        <AreaChart data={impressionDataWithDate}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            minTickGap={32}
            tickFormatter={(value) => {
              const date = new Date(value);
              const language = i18n.language || "en";
              const formatter = new Intl.DateTimeFormat(language, {
                month: "short",
                day: "numeric",
              }).formatToParts(date);

              const month =
                formatter.find((part) => part.type === "month")?.value || "";
              const day =
                formatter.find((part) => part.type === "day")?.value || "";
              return `${day} ${month.charAt(0).toUpperCase()}${month.slice(1)}`;
            }}
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                labelFormatter={(value) => {
                  const date = new Date(value);
                  const language = i18n.language || "en";
                  const formatter = new Intl.DateTimeFormat(language, {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }).formatToParts(date);

                  const month =
                    formatter.find((part) => part.type === "month")?.value ||
                    "";
                  const day =
                    formatter.find((part) => part.type === "day")?.value || "";
                  const year =
                    formatter.find((part) => part.type === "year")?.value || "";
                  return `${day} ${month.charAt(0).toUpperCase()}${month.slice(
                    1
                  )} ${year}`;
                }}
                formatter={(value) => (
                  <div className="flex flex-1 justify-between leading-none items-center">
                    <span className="text-muted-foreground">
                      {t("ds.impression")}
                    </span>
                    <span className=" font-medium tabular-nums text-foreground ml-4">
                      {value.toLocaleString()}
                    </span>
                  </div>
                )}
                indicator="dot"
              />
            }
          />
          <ChartLegend content={<CustomLegend />} />
          <Area
            dataKey="impressionAmount"
            type="linear"
            fill={impressionConfig.amount.color}
            fillOpacity={0.4}
            stroke={impressionConfig.amount.color}
            stackId="a"
          />
        </AreaChart>
      </ChartContainer>
    </div>
  );
}
