import { LargeText } from "@/components/typography/Typography";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { iCollection } from "@/services/collection";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SaleEventsDatatable from "../buybacks/SaleEventsDatatable";

export default function SaleEventsTab({
  collection,
}: {
  collection: iCollection;
}) {
  // set page title
  const { setTitle } = useSectionTitle();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(`${t("collection.tab_buybacks")} - ${collection.name}`);
  }, [setTitle]);

  return (
    <div className="border rounded-lg p-4">
      <SaleEventsDatatable collection={collection} />
    </div>
  );
}
