import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import InfoStepper from "./InfoStepper";
import CollectionTeamOnboarding from "./onboarding/CollectionTeamOnboarding";
import Details from "./onboarding/Details";
import Prices from "./onboarding/Prices";
import CollectionTabsWrapper from "@/components/collection/CollectionTabsWrapper";
import {
  iCollection,
  getCollection,
  onBoardingCompleted,
  onBoardingLastStep,
} from "@/services/collection";
import { iGroup, getGroup } from "@/services/groups";
import { useState, useEffect } from "react";
import Images from "./onboarding/Images";
import CompletionMessage from "./onboarding/CompletionMessage";
import PaymentMethodsWrapper from "./onboarding/PaymentMethodsWrapper";
import getLink, { LINK_TYPE } from "@/lib/links";
import { Skeleton } from "@/components/ui/skeleton";
import ErrorPage from "@/components/errors/ErrorPage";
import { t } from "i18next";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useRbac } from "@/hooks/rbac";
import axios, { AxiosError } from "axios";
import { selectUser } from "@/store/userSlice";
import { useSelector } from "react-redux";
import SelectHotel from "./onboarding/SelectHotel";
import HotelData from "./onboarding/HotelData";
import HotelChain from "./onboarding/HotelChain";
import HotelPrice from "./onboarding/Price";
import HotelBenefits from "./onboarding/HotelBenefits";
import LoyaltyContainer from "./loyalty/loyalty_container"; // Import LoyaltyContainer

const isOnboardingRoute = (pathname: string) => {
  return pathname.includes("infos");
};
export interface CollectionPageChildProps {
  collection?: iCollection;
  group?: iGroup;
  loading: boolean;
  onRefresh(): Promise<void>;
}

export default function CollectionPage() {
  const { collectionId } = useParams();

  const [collection, setCollection] = useState<iCollection>();

  const [group, setGroup] = useState<iGroup>();

  const { isSuperAdmin } = useRbac();

  const [isLoading, setIsLoading] = useState(true);

  const { setLogoHomeLink, homeLink, setIsFree } = useSectionTitle();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const load = async () => {
    //console.log("refresh collectionId: ", collectionId);
    if (!collectionId) return;
    setIsLoading(true);
    try {
      const [c, g] = await Promise.all([
        getCollection(collectionId),
        getCollection(collectionId).then((c) => getGroup(c.groupId)),
      ]);

      setCollection(c);
      setGroup(g);
    } catch (error: unknown) {
      console.log("Error loading collection:", error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.log("Error details:", axiosError.response?.data);
        if (
          axiosError.response?.status === 404 ||
          axiosError.response?.status === 500
        ) {
          navigate(getLink(LINK_TYPE.HOME));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // REDIRECT FOR ONBOARDING INCOMPLETE
    if (!isSuperAdmin && collection && !isOnboardingRoute(pathname)) {
      const onboardingComplete = onBoardingCompleted(collection);
      //const onboardingComplete = true;
      //const canUpdate = can(COLLECTION_PERMISSIONS.update, collection);
      //console.log("onboardingComplete", onboardingComplete);
      if (!onboardingComplete) {
        /*const redirectTo = getLink(LINK_TYPE.HOME);
        console.log(
          "CollectionPage redirecting for onboarding incomplete",
          redirectTo
        );
        navigate(redirectTo);*/
        const lastStep = onBoardingLastStep(collection);
        navigate(`/collection/${collection._id}/infos/${lastStep}`);
        return;
      }
    }
    setIsFree(collection?.isFree || false);
  }, [collection]);

  useEffect(() => {
    if (isSuperAdmin && collection) {
      // set logo home link to current collection group page
      setLogoHomeLink(`/group/${collection.groupId}`);
    }
  }, [collection, isSuperAdmin]);

  useEffect(() => {
    if (!user) return;
    load();
  }, [collectionId, user]);

  if (isLoading)
    return <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />;

  if (!collection || !group)
    return <ErrorPage message={t("error.not_found")} />;

  return (
    <Routes>
      <Route
        path="infos"
        element={<InfoStepper loading={isLoading} collection={collection} />}
      >
        <Route
          path="selecthotel"
          element={
            <SelectHotel
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="hoteldata"
          element={
            <HotelData
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="hotelprice"
          element={
            <HotelPrice
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="hotelbenefits"
          element={
            <HotelBenefits
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="hotelchain"
          element={
            <HotelChain
              collection={collection}
              group={group}
              loading={isLoading}
              onRefresh={load}
            />
          }
        />
        {/* <Route
          path="complete"
          element={
            <CompletionMessage
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        /> */}
        {/* <Route path="stats" element={<HotelStats />} /> */}
        {/* <Route path="payments" element={<PaymentMethods />} /> */}
      </Route>
      <Route path="loyalty/*" element={<LoyaltyContainer onRefresh={load} />} />
      <Route
        path="*"
        element={
          <CollectionTabsWrapper
            collection={collection}
            group={group}
            onRefresh={load}
            loading={isLoading}
          />
        }
      ></Route>
    </Routes>
  );
}
