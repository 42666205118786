import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import { initTagManager } from "./services/tagmanager";

// initialize axios
import { initializeAxios } from "./services/axios";

initializeAxios();
// initi18next();

if (import.meta.env.MODE === "production") initTagManager();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
