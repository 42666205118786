import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

export default function DashboardSkeleton() {
  return (
    <div className="flex flex-col space-y-3 p-6">
      {[...Array(3)].map((_, i) => (
        <React.Fragment key={`sk_${i}`}>
          <Skeleton className="bg-gray-200 h-[32px] w-[170px] rounded-lg" />
          <div className="flex flex-row gap-2">
            <Skeleton className="bg-gray-200 h-[32px] w-[120px] rounded-lg" />
            <Skeleton className="bg-gray-200 h-[32px] w-[120px] rounded-lg" />
            <Skeleton className="bg-gray-200 h-[32px] w-[120px] rounded-lg" />
          </div>
          <div className="bg-gray-200 y-4 h-[1px]"></div>
          <div className="flex flex-row gap-3">
            {[...Array(6)].map((_, j) => (
              <Skeleton
                key={`sk_inner_${i}_${j}`}
                className="bg-gray-200 w-[320px] h-[240px] rounded-lg"
              />
            ))}
          </div>
          <div className="pt-20"></div>
        </React.Fragment>
      ))}
    </div>
  );
}
