import AuthRoute from "@/components/auth/AuthRoute";
import ProtectedRoute from "@/components/auth/ProtectedRoute";
import AuthLayout from "@/layout/AuthLayout";
import Layout from "@/layout/Layout";
import LoginPage from "@/pages/auth/LoginPage";
import RegisterPage from "@/pages/auth/RegisterPage";
import ResetPasswordPage from "@/pages/auth/ResetPasswordPage";

import Dashboard from "@/pages/dashboard/Dashboard";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ForgotPasswordPage from "@/pages/auth/ForgotPasswordPage";

import { useEffect } from "react";
import getLink, { LINK_TYPE } from "@/lib/links";
import VerifyEmailPage from "@/pages/auth/VerifyEmailPage";
import GroupPage from "@/pages/group/GroupPage";
import PaymentMethodsPage from "@/pages/paymentmethods/PaymentMethodsPage";
import PaymentMethodPage from "@/pages/paymentmethods/sub/PaymentMethodPage";

import CollectionPage from "@/pages/collection/CollectionPage";
import Account from "@/pages/account/Account";
import DeveloperSettings from "@/pages/accountSettings/DeveloperSettings";
import HotelIntegrationDatatable from "@/pages/Admin/HotelRegistration/HotelsIntegratedDatatable";
import DataAnalysisMasks from "@/pages/Admin/DataAnalysisMasks/DataAnalysisMasks";
import Users from "@/pages/Admin/Users/Users";
import AdminLayout from "@/layout/AdminLayout";
import Partners from "@/pages/Admin/Partners/Partners";
import Invoices from "@/pages/Admin/Invoices/Invoices";
import Emails from "@/pages/Admin/Emails/Emails";
import SupportFaqs from "@/pages/Admin/SupportFaqs/SupportFaqs";
import Documents from "@/pages/Admin/Documents/Documents";
import Translations from "@/pages/Admin/Translations/Translations";
import { useRbac } from "@/hooks/rbac";
import DashboardAdmin from "@/pages/dashboard/DashboardAdmin";
import Nfts from "@/pages/Admin/Nfts/Nfts";
import { useAuthContext } from "@/context/AuthContext";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { RenderDocument } from "@/components/documents/RenderDocument";

export function Redirect({ to }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
    // console.log("Redirecting to...", to);
  }, []);
  return <></>;
}

const AppRoutes = () => {
  const { isAuthenticated, isVerified } = useAuthContext();
  const { isSuperAdmin } = useRbac();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(
      "AppRoutes - useEffect redirect",
      isAuthenticated,
      isVerified,
      isSuperAdmin
    );
    if (
      isAuthenticated !== null &&
      isVerified !== null &&
      isSuperAdmin !== null
    ) {
      if (!isAuthenticated && location.pathname == "/auth") {
        console.log("Redirect 1");
        return navigate(getLink(LINK_TYPE.LOGIN));
      }
      if (
        isAuthenticated &&
        isVerified === false &&
        location.pathname !== getLink(LINK_TYPE.VERIFY_EMAIL)
      ) {
        console.log("Redirect 2");
        return navigate(getLink(LINK_TYPE.VERIFY_EMAIL));
      }
      if (
        isAuthenticated &&
        location.pathname.includes("/auth") &&
        isVerified === true &&
        location.pathname !== getLink(LINK_TYPE.VERIFY_EMAIL)
      ) {
        console.log("Redirect 3");
        return navigate(getLink(LINK_TYPE.HOME));
      }
      if (location.pathname === "/" && isSuperAdmin) {
        console.log("Redirect 4");
        return navigate("/admin");
      }
      if (location.pathname.includes("/admin") && !isSuperAdmin) {
        console.log("Redirect 5");
        return navigate(getLink(LINK_TYPE.HOME));
      }
    }
  }, [isSuperAdmin, isAuthenticated, isVerified, location.pathname]);

  return (
    <Routes>
      <Route
        path={getLink(LINK_TYPE.AUTH)}
        element={
          <AuthRoute>
            <AuthLayout />
          </AuthRoute>
        }
      >
        <Route path={getLink(LINK_TYPE.LOGIN)} element={<LoginPage />} />
        {/* <Route path="login-token" element={<Auth mode="login_token" />} /> */}
        <Route path={getLink(LINK_TYPE.REGISTER)} element={<RegisterPage />} />
        <Route
          path={getLink(LINK_TYPE.FORGOT_PASSWORD)}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={getLink(LINK_TYPE.RESET_PASSWORD)}
          element={<ResetPasswordPage />}
        />
        <Route
          path={getLink(LINK_TYPE.VERIFY_EMAIL)}
          element={<VerifyEmailPage />}
        />
        <Route path="" element={<Redirect to={getLink(LINK_TYPE.LOGIN)} />} />
      </Route>
      <Route
        path={getLink(LINK_TYPE.HOME)}
        element={
          <>
            <Layout />
          </>
        }
      >
        <Route
          path={getLink(LINK_TYPE.TOS_DASHBOARD)}
          element={
            <>
              <RenderDocument name="dashboard_tos" />
            </>
          }
        />
        <Route
          path={getLink(LINK_TYPE.PRIVACY_DASHBOARD)}
          element={
            <div>
              <RenderDocument name="dashboard_privacy" />
            </div>
          }
        />
        <Route
          path={getLink(LINK_TYPE.COOKIE_DASHBOARD)}
          element={
            <div>
              <RenderDocument name="Cookie Policy" />
            </div>
          }
        />
      </Route>

      {/* Admin routes */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route index element={<DashboardAdmin />} />
        <Route path={getLink(LINK_TYPE.USERS)} element={<Users />} />
        <Route path={getLink(LINK_TYPE.PARTNERS)} element={<Partners />} />
        <Route path={getLink(LINK_TYPE.INVOICES)} element={<Invoices />} />
        <Route path={getLink(LINK_TYPE.EMAILS)} element={<Emails />} />
        <Route path={getLink(LINK_TYPE.SUPPORT)} element={<SupportFaqs />} />
        <Route path={getLink(LINK_TYPE.DOCUMENTS)} element={<Documents />} />
        <Route
          path={getLink(LINK_TYPE.TRANSLATIONS)}
          element={<Translations />}
        />
        <Route path={getLink(LINK_TYPE.NFTS)} element={<Nfts />} />
        {/* 
        
        <Route
          path={getLink(LINK_TYPE.PROMOCODES).replace("/", "")}
          element={<PromoCodes />}
        />
        
        <Route path={getLink(LINK_TYPE.AI).replace("/", "")} element={<Ai />} />
        <Route
          path={getLink(LINK_TYPE.REPORTS).replace("/", "")}
          element={<Reports />}
        />
        <Route
          path={getLink(LINK_TYPE.QUOTE).replace("/", "")}
          element={<Quote />}
        />
        <Route
          path={getLink(LINK_TYPE.COLLECTION_MANAGER).replace("/", "")}
          element={<CollectionManager />}
        />
        <Route
          path={getLink(LINK_TYPE.EMAILS_SERVICE).replace("/", "")}
          element={<EmailsService />}
        /> */}
        <Route
          path={getLink(LINK_TYPE.HOTEL_REGISTRATION_MANAGER)}
          element={<HotelIntegrationDatatable />}
        />
      </Route>

      <Route
        path={getLink(LINK_TYPE.HOME)}
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route
          path={getLink(LINK_TYPE.PAYMENT_METHODS)}
          element={<PaymentMethodsPage />}
        />
        <Route
          path={getLink(LINK_TYPE.PAYMENT_METHOD, {
            __PAYMENTMETHODID__: ":id",
          })}
          element={<PaymentMethodPage />}
        />
        <Route
          path={getLink(LINK_TYPE.DEVELOPER)}
          element={<DeveloperSettings />}
        />
        <Route path={getLink(LINK_TYPE.ACCOUNT)} element={<Account />} />

        {[
          getLink(LINK_TYPE.GROUP, {
            __GROUPID__: ":groupId",
          }),
          getLink(LINK_TYPE.GROUP, {
            __GROUPID__: ":groupId",
          }) + "/*",
        ].map((path, key) => {
          return (
            <Route
              key={"grouppath:" + key}
              path={path}
              element={<GroupPage />}
            ></Route>
          );
        })}

        {[
          getLink(LINK_TYPE.COLLECTION, {
            __COLLECTIONID__: ":collectionId",
          }),
          getLink(LINK_TYPE.COLLECTION, {
            __COLLECTIONID__: ":collectionId",
          }) + "/*",
        ].map((path, key) => {
          return (
            <Route
              key={"collectionpath:" + key}
              path={path}
              element={<CollectionPage />}
            ></Route>
          );
        })}

        {/* <Route path="bookings" element={<BookingList />} /> */}

        {/* Admin routes */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path={getLink(LINK_TYPE.USERS)} element={<Users />} />
          <Route
            path={getLink(LINK_TYPE.HOTEL_REGISTRATION_MANAGER)}
            element={<HotelIntegrationDatatable />}
          />
          <Route
            path={getLink(LINK_TYPE.DATA_ANALYSIS_MASKS)}
            element={<DataAnalysisMasks />}
          />
        </Route>

        <Route path="*" element={<Redirect to={getLink(LINK_TYPE.HOME)} />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
