import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import {
  getAnalysisMaskData,
  MaskDataResponse,
} from "@/services/dataanalysismask";
import { isEmptyObject } from "@/utils/generic";
import { useTranslation } from "react-i18next";
import AppreciationStars from "../analytics/AppreciationStars";
import Customer from "../analytics/Customer";
import GeneratedTak from "../analytics/GeneratedTak";
import PerformanceSection from "../analytics/PerformanceSection";
import RevenueHighlight from "../analytics/RevenueHighlight";
import Impression from "../analytics/Impression";
import SearchChart from "../analytics/SearchChart";
import { useEffect, useState } from "react";
import noResults from "@/assets/img/dash_no_group_no_collection.png";
import freeUserIta from "@/assets/img/banner_certificates_it.png";
import freeUserEng from "@/assets/img/banner_certificates_en.png";
import freeUserEsp from "@/assets/img/banner_certificates_es.png";
import i18next from "i18next";
import { AlertTriangle, Award } from "lucide-react";
import { Button } from "@/components/ui/button";
import { JSX } from "react/jsx-runtime";
import GoToProModal from "../analytics/GoToProModal";
import { useModal } from "@/context/ModalContext";
import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";
import { iCollection } from "@/services/collection";
import CompletionMessage from "../onboarding/CompletionMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import LoyaltyCompleteMessage from "../loyalty/Loyalty_complete_message";

interface TimePeriod {
  value: string;
  label: string;
}

export default function DirectSearch({
  collection,
  isFreeUser,
}: {
  collection: iCollection;
  isFreeUser?: boolean;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const TIME_PERIODS: TimePeriod[] = [
    { value: "7", label: "ds.lastsevenDays" },
    { value: "30", label: "ds.lastthirtyDays" },
  ];
  const location = useLocation();
  const navigate = useNavigate();

  const DEFAULT_TIME_PERIOD = TIME_PERIODS[1].value;

  const [period, setPeriod] = useState(DEFAULT_TIME_PERIOD);

  const { showModal } = useModal();
  const [graphData, setGraphData] = useState<MaskDataResponse>();

  const loadMaskData = async (period: number) => {
    try {
      setIsLoading(true);
      const datas = await getAnalysisMaskData({
        collectionId: collection._id,
        period: period,
      });
      // base data always available from the api
      setGraphData(datas);

      setIsLoading(false);
    } catch (error) {
      console.log("Error getting mask data", error);
    }
  };

  useEffect(() => {
    loadMaskData(Number(DEFAULT_TIME_PERIOD));
  }, []);

  useEffect(() => {
    if (location.state?.openModal) {
      showModal(<CompletionMessage />, {
        title: "",
        description: "",
      });
      // Replace current location with same URL but without state
      navigate(location.pathname, { replace: true });
    }

    if (location.state?.openModalLoyalty) {
      showModal(<LoyaltyCompleteMessage />, {
        title: "",
        description: "",
      });
      // Replace current location with same URL but without state
      navigate(location.pathname, { replace: true });
    }
  }, [location, showModal, navigate]);

  useEffect(() => {
    loadMaskData(Number(period));
    fireJuneEvent("change_period", {
      period: period,
      collectionName: collection.name,
      collectionId: collection._id,
    });
  }, [period]);

  return isLoading ? (
    <div className="flex gap-4 h-[70vh]">
      <div className="flex flex-col gap-2 w-full">
        <div
          className="h-[8px] bg-black w-0"
          style={{
            animation: "progressAnimation 30s ease-in-out forwards",
          }}
        ></div>
        <p className="text-md mt-5 text-center mb-3">
          {t("collection.ds.loading")}
        </p>
        <Skeleton className="w-full h-[48px] bg-gray-200" />
        <Skeleton className="w-full h-[443px] bg-gray-200" />
        <Skeleton className="w-full h-[523px] bg-gray-200" />
      </div>
    </div>
  ) : graphData && !isEmptyObject(graphData) ? (
    <div className="w-full flex flex-col mt-8 gap-2">
      {!collection?.isLoyaltyEnabled && (
        <div className="w-full flex justify-between gap-2 bg-black rounded-xl p-6 mb-2">
          <div className="flex flex-col gap-2 text-white">
            <div className="flex gap-2 ">
              <div className="flex items-center justify-center ">
                <Award className="h-5 w-5 font-medium" size={24} />
              </div>

              <p className="text-2xl font-medium">{t("loyalty.title")}</p>
            </div>
            <p className="text-sm">{t("loyalty.subtitle")}</p>
          </div>
          <div className="flex items-center">
            <Separator className="mx-6" orientation="vertical" />
            <Button
              variant="takyon"
              onClick={() => navigate(`/collection/${collection._id}/loyalty`)}
            >
              {t("loyalty.activate_promo")}
            </Button>
          </div>
        </div>
      )}
      {collection?.isLoyaltyEnabled && (
        <div className="w-full flex justify-between gap-2 bg-black rounded-xl p-6 mb-2">
          <div className="flex flex-col gap-2 text-white">
            <div className="flex gap-2 ">
              <div className="flex items-center justify-center ">
                <Award className="h-5 w-5 font-medium" size={24} />
              </div>

              <p className="text-2xl font-medium">
                {t("loyalty.complete.title")}
              </p>
            </div>
            <p className="text-sm">{t("loyalty.complete.subtitle")}</p>
          </div>
          <div className="flex items-center">
            <Separator className="mx-6" orientation="vertical" />
            <Button
              variant="outline2"
              onClick={() => {
                showModal(<LoyaltyCompleteMessage />);
              }}
            >
              <AlertTriangle size={16} className="mr-2" />
              {t("loyalty.instructions")}
            </Button>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <p className="text-xl font-semibold mb-5">{t("collection.ds.title")}</p>
        <Select value={period} onValueChange={setPeriod}>
          <SelectTrigger className="w-[180px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {TIME_PERIODS.map((period) => (
              <SelectItem key={period.value} value={period.value}>
                {t(period.label)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="w-full flex flex-wrap gap-4">
        {Object.entries(graphData).map(([key, value]) => {
          if (isEmptyObject(value)) return null;
          switch (key) {
            case "search":
              return <SearchChart key={key} data={graphData} />;
            case "impression":
              return <Impression key={key} data={graphData} />;
            case "revenue":
              return (
                <RevenueHighlight
                  key={key}
                  value={(graphData as any)[key].value}
                  unit="€"
                  delta={(graphData as any)[key].delta}
                />
              );
            case "appreciation":
              return (
                <AppreciationStars
                  key={key}
                  value={(graphData as any)[key].value}
                  delta={(graphData as any)[key].delta}
                />
              );
              break;
            case "performance":
              return (
                <PerformanceSection key={key} data={(graphData as any)[key]} />
              );
            case "customer":
              return <Customer key={key} data={(graphData as any)[key]} />;
            case "nft":
              return (
                <GeneratedTak
                  key={key}
                  data={(graphData as any)[key]}
                ></GeneratedTak>
              );
          }
        })}
      </div>
      {/* {isFreeUser && (
        <div className="flex p-3 gap-2 flex-col items-center justify-center bg-black mt-4 rounded-xl text-white">
          <div className="flex items-center justify-center items-center gap-2 mt-10">
            <TrendingUp className="hidden md:block" />
            <p className="text-2xl font-semibold">
              {t("collection.ds.go_to_pro")}{" "}
              <span className="text-takyon">Pro</span>
            </p>
          </div>
          <p className="text-sm font-medium">
            {t("collection.ds.go_to_pro_description")}
          </p>
          <div className="flex flex-col md:flex-row items-center md:items-start gap-10 my-8 py-5 px-10 rounded-xl bg-white bg-opacity-10">
            <p className="text-3xl font-semibold">
              + {t("collection.ds.traffic")}
            </p>
            <p className="text-3xl font-semibold">
              + {t("collection.ds.click")}
            </p>
            <p className="text-3xl font-semibold">
              + {t("collection.ds.reservations")}
            </p>
          </div>
          <div className="w-full mb-5 flex justify-center">
            <Button
              variant="gradient"
              onClick={() =>
                showModal(<GoToProModal />, {
                  title: t("pro_modal.title"),
                  description: "",
                  className: "max-w-max",
                })
              }
            >
              {t("collection.ds.ask_demo")}
            </Button>
          </div>
          <img
            src={
              i18next.language === "it"
                ? freeUserIta
                : i18next.language === "es"
                ? freeUserEsp
                : freeUserEng
            }
            alt="Free User Banner"
            className="w-full max-w-[1024px] mt-4"
          />
        </div>
      )} */}
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={noResults} alt="No results" className="max-w-xs mb-4" />
    </div>
  );
}
