import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { CollectionPageChildProps } from "../CollectionPage";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import MapsAutocomplete from "@/components/mapsautocomplete/MapsAutocomplete";
import { patchCollection } from "@/services/collection";

export default function HotelData({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = z.object({
    publicWebsite: z
      .string()
      .url(t("auth.invalid_url"))
      .refine((val) => val.startsWith("https://"), {
        message: t("auth.invalid_url"),
      }),
    publicEmail: z.string().email(t("auth.invalid_email")),
    phoneNumber: z.string().min(10, t("auth.valid_phone_number")),
    address: z.any(),
    cin: z.string().optional(),
    cir: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      publicWebsite: collection?.census?.website || "",
      publicEmail:
        collection?.publicContacts?.find((c) => c.type === "email")?.value ||
        "",
      phoneNumber:
        collection?.publicContacts?.find((c) => c.type === "phone")?.value ||
        "",
      address: collection?.census?.location || "",
      cin: collection?.census?.cin || "",
      cir: collection?.census?.cir || "",
    },
  });

  useEffect(() => {
    setBackBtn(true);
    setProgress(40);
    if (form.getValues("publicWebsite") !== "") {
      form.trigger("publicWebsite");
    }
  }, []);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    const contacts = [];

    contacts.push({
      type: "website",
      value: data.publicWebsite,
    });
    contacts.push({
      type: "email",
      value: data.publicEmail,
    });
    contacts.push({
      type: "phone",
      value: data.phoneNumber,
    });

    try {
      await patchCollection({
        collectionId: collection?._id,
        publicContacts: contacts,
        census: {
          ...collection?.census,
          website: data.publicWebsite,
          location: data.address,
          cin: data.cin,
          cir: data.cir,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      await onRefresh();
      navigate(`/collection/${collection?._id}/infos/hotelprice`);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-5 mb-10">
        <p className="text-base font-medium">{t("onboarding.step")} 2</p>
        <p className="text-2xl font-medium">
          {t("onboarding.hotel_data_title")}
        </p>
      </div>
      <div className="flex-1 overflow-y-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="publicWebsite"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("collection.public_website")}*</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="https://www.example.com"
                        onBlur={(e) => {
                          field.onBlur();
                          if (e.target.value) {
                            form.trigger("publicWebsite");
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          form.clearErrors("publicWebsite");
                        }}
                      />
                    </FormControl>
                    <FormMessage className="text-red-500" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("collection.census_location")}*</FormLabel>
                    <FormControl>
                      <MapsAutocomplete
                        onSelect={(address: any) => {
                          field.onChange(address);
                        }}
                        defaultValue={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="publicEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("onboarding.email")}*</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="hotel@example.com"
                        onBlur={(e) => {
                          field.onBlur();
                          if (e.target.value) {
                            form.trigger("publicEmail");
                          }
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          form.clearErrors("publicEmail");
                        }}
                      />
                    </FormControl>
                    <FormMessage className="text-red-500" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("onboarding.phone")}*</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={t("auth.phone_number_placeholder")}
                        onBlur={(e) => {
                          field.onBlur();
                          if (e.target.value) {
                            form.trigger("phoneNumber");
                          }
                        }}
                        onChange={(e) => {
                          // Allow digits and + for international prefix
                          const value = e.target.value.replace(/[^\d+]/g, "");
                          e.target.value = value;
                          field.onChange(value);
                          form.clearErrors("phoneNumber");
                        }}
                      />
                    </FormControl>
                    <FormMessage className="text-red-500" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="cin"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("collection.cin")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("collection.cin_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="cir"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("collection.cir")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("collection.cir_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>

      <div className="mt-10">
        <div className="flex flex-row justify-between">
          <Button
            type="button"
            onClick={() =>
              navigate(`/collection/${collection?._id}/infos/selecthotel`)
            }
          >
            {t("auth.back")}
          </Button>
          {!isLoading ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={form.handleSubmit(onSubmit)}
              disabled={!form.formState.isValid || form.formState.isSubmitting}
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button variant="takyon" disabled>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("buttons.loading")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
