import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/context/AuthContext";
import { H2 } from "../typography/Typography";
import getLink, { LINK_TYPE } from "@/lib/links";
import { getUrlParameter } from "@/utils/generic";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { phoneCodes } from "./PhoneCodes";
import { cosent, patchUser } from "@/services/auth";
import { fireTagManagerEvent } from "@/services/tagmanager";
import { fireJuneEvent } from "../analytics/JuneAnalytics";

export function RegisterForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { register, sendVerificationEmail } = useAuthContext();

  const formSchema = z.object({
    firstName: z.string().min(1, t("account.first_name_placeholder")),
    lastName: z.string().min(1, t("account.last_name_placeholder")),
    phonePrefix: z.string(),
    phoneNumber: z.string().min(5, t("auth.valid_phone_number")),
    email: z.string().email(t("auth.invalid_email")),
    password: z.string().min(6, t("auth.password_too_short")),
    acceptTerms: z.boolean().refine((val) => val === true, {
      message: t("auth.must_accept_terms"),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phonePrefix: "+39",
      phoneNumber: "",
      email: getUrlParameter("email") ?? "",
      password: "",
      acceptTerms: false,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const user = await register(values);
      // Update user profile with additional information
      await patchUser({
        firstName: values.firstName,
        secondName: values.lastName,
        phonePrefix: values.phonePrefix,
        phone: values.phoneNumber,
      });
      fireTagManagerEvent("register", { email: values.email });
      fireJuneEvent("register", { email: values.email });

      await cosent({
        email: values.email,
        target: "dashboard-tos-privacy",
        consent: true,
      });

      await sendVerificationEmail({
        redirect: getLink(LINK_TYPE.VERIFY_EMAIL),
      });
      // navigate(getLink(LINK_TYPE.HOME)); // Navigate after successful registration
    } catch (error: any) {
      console.log(error);

      const errorMessage = String(error.response.data.message).toLowerCase();

      if (errorMessage.includes("user already exists"))
        form.setError("email", {
          type: "manual",
          message: String(t("auth.email_already_taken")),
        });
    }
    setIsLoading(false);
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <p className="text-2xl font-medium">{t("auth.register_title")}</p>
      <p className="text-base font-medium text-gray-500">
        {t("auth.register_subtitle")}
      </p>
      <div className="mt-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex flex-col md:flex-row gap-2">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("account.first_name")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("account.first_name_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("account.last_name")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("account.last_name_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex gap-2">
              <FormField
                control={form.control}
                name="phonePrefix"
                render={({ field }) => (
                  <FormItem className="w-[100px]">
                    <FormLabel>{t("account.phone_prefix")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {phoneCodes.map(({ country, code }) => (
                          <SelectItem key={code} value={code}>
                            {country} {code}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>{t("auth.phone")}</FormLabel>
                    <FormControl>
                      <Input
                        type="tel"
                        placeholder={t("auth.phone_number_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("auth.email")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("auth.email")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("auth.password_register")}</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        placeholder={t("auth.password_register")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="my-10">
              <FormField
                control={form.control}
                name="acceptTerms"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <p className="text-xs font-medium">
                        {t("auth.accept_terms")}{" "}
                        <a
                          href="/tos"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline cursor-pointer"
                        >
                          {t("auth.terms_of_service")}
                        </a>{" "}
                        {t("auth.accept_terms_and")}{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          className="underline cursor-pointer"
                        >
                          {t("auth.privacy_policy")}
                        </a>
                      </p>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-between items-center">
              <div className="mt-1 text-sm">
                {t("auth.login_ask")}{" "}
                <Link
                  to={getLink(LINK_TYPE.LOGIN)}
                  className="underline font-medium"
                >
                  {t("auth.login_button")}
                </Link>
              </div>
              <Button
                size={"sm"}
                type="submit"
                variant={"takyon"}
                disabled={isLoading || !form.watch("acceptTerms")}
              >
                {isLoading ? t("auth.registering") : t("auth.register")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
